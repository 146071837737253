import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC } from 'react';
import { CloseDialogButton } from '../../../design/Button/CloseDialogButton';
import { Title } from '../../../design/theme/shared-style';
import { ConfirmButton } from '../../../components/Shared/Buttons/ConfirmButton';
import ListCompanyTable from '../../../components/User/Employee/ListEmployeeTable';
import { User } from '../../../types/user.dto';


export const NewUserDetailsModal: FC<{
  onClose: () => void,
  open: boolean,
  submit: () => void,
  reject: () => void,
  users?: User[] | null
}> = ({ onClose, open, users, submit, reject}) => {

  const handlerClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    submit();
    onClose();
  };

  const handleReject = () => {
    reject();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'xl'}>
      <DialogTitle textAlign="left" mb={1} style={{ marginBottom: '0px' }}>
        <Title>Autorización de alta de Usuario(s)</Title>
        <CloseDialogButton onClose={handlerClose} />
      </DialogTitle>
      <DialogContent>
        {users &&
          <ListCompanyTable usersToLoad={users} usersPreloaded={true}></ListCompanyTable>
        }
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>Autorizar</strong>
        </ConfirmButton>
        <ConfirmButton handleClick={handleReject}>
          <strong>Rechazar</strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  )
};