import { SalaryOnDemandReportDTO } from "../../types/salary_ondemand_report.dto";
import { http } from "../../utilities/http/http";

export async function getSalaryOnDemandData(): Promise<SalaryOnDemandReportDTO[]> {
    try {
        const response = await http.get<{
            success: boolean;
            data: any;
        }>('transaction/dashboard/salary-on-demand-report');
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error');
        }
    } catch (err: any) {
        console.log(err);
        throw new Error(err.message);
    }
}