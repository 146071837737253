import { Button } from 'design';
import { palette } from 'design/theme/palette';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${palette.white.main} !important;
  color: ${palette.black.main} !important;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed !important;
    `}
`;

type ButtonProps = {
  handleClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
};

export const ConfirmButton: FC<ButtonProps> = ({ handleClick, children, disabled }) => {
  return (
    <StyledButton onClick={handleClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};