import styled from "@emotion/styled";
import { ComplaintPriorityEnum, ComplaintStatus, ComplaintStatusEnum } from "../../enums/complaint.enum";

export const Container = styled.div`
    display: flex;
`;

export const TaskList = styled.div`
    min-height: 100px;
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    min-width: 341px;
    border-radius: 5px;
    padding: 15px 15px;
    margin-right: 45px;
`;

export const TaskColumnStyles = styled.div`
    margin: 8px;
    display: flex;
    width: 100%;
    min-height: 80vh;
`;

export const BaseTitle = styled.span`
    padding: 2px 10px;
    border-radius: 5px;
    align-self: flex-start;
    font-weight: 600;
    white-space: nowrap;
    font-family:'"Roboto","Helvetica","Arial",sans-serif';
`;

export const TitleOpen = styled(BaseTitle)`
    color: #d9534f;
    background: rgba(217, 83, 79, 0.15);
`;

export const TitleInvestigation = styled(BaseTitle)`
    color: #337ab7;
    background: rgba(51, 122, 183, 0.15);
`;

export const TitleProgress = styled(BaseTitle)`
    color: #f0ad4e;
    background: rgba(240, 173, 78, 0.15);
`;

export const TitleClose = styled(BaseTitle)`
    color: #10957d;
    background: rgba(16, 149, 125, 0.15);
`;

export const PriorityHigh = styled(BaseTitle)`
    color: #DC143C;
    background: rgba(220, 20, 60, 0.15);
`;

export const PriorityMedium = styled(BaseTitle)`
    color: #FF7F50;
    background: rgba(255, 127, 80, 0.15);
`;

export const PriorityLow = styled(BaseTitle)`
    color: #87CEEB;
    background: rgba(135, 206, 235, 0.15);
`;

export const StandarInformation = styled(BaseTitle)`
    color: #8C8C8C;
    background: rgba(140, 140, 140, 0.15);
    font-family:'"Roboto","Helvetica","Arial",sans-serif';
`;


export const titleStyles = {
    [ComplaintStatus.OPEN]: TitleOpen,
    [ComplaintStatus.INVESTIGATION]: TitleInvestigation,
    [ComplaintStatus.PROGRESS]: TitleProgress,
    [ComplaintStatus.CLOSE]: TitleClose
};

export const titleStylesEnglish = {
    [ComplaintStatusEnum.OPEN]: TitleOpen,
    [ComplaintStatusEnum.INVESTIGATION]: TitleInvestigation,
    [ComplaintStatusEnum.PROGRESS]: TitleProgress,
    [ComplaintStatusEnum.CLOSE]: TitleClose
};

export const priorityStyles = {
    [ComplaintPriorityEnum.HIGH]: PriorityHigh,
    [ComplaintPriorityEnum.MEDIUM]: PriorityMedium,
    [ComplaintPriorityEnum.LOW]: PriorityLow,
};

export const CardInformationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    color: #7d7d7d;
`;

export const LabelInformation = styled.div`
    margin-right: 5px; 
    font-family:"Roboto","Helvetica","Arial",sans-serif;
    margin-bottom: 10px;
`;

export const BodyContainer = styled.div`
    border-radius: 8px;
    border: 1px solid rgb( 232, 234, 237 );
    padding: 24px 32px 32px; 
    margin-top: 25px
`;

export const BodyText = styled.p`
    font-family:"Roboto","Helvetica","Arial",sans-serif;
    font-size: 1.1rem
`;

export const BaseSection = styled.div`
    border: 1px solid rgb( 232, 234, 237 );
    padding: 24px 32px 32px;
    margin-top: 22px;
`;

export const LeftSection = styled(BaseSection)`
    width: 70%
`;

export const RightSection = styled(BaseSection)`
    width: 21%
`;

export const UserItem = styled.li`
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    display: flex;
    align-items: center;
    margin: 7px 0
`;

export const UserList = styled.li`
    list-style: none;
    padding: 0;
    max-height: 445px;
    overflow-y: auto;
`;