import { Box } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
`;

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index } = props;

  return (
    <StyledDiv
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </StyledDiv>
  );
};
