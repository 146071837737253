import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { intl } from 'utilities/i18n/intl.utility';
import { Dashboard } from 'views/Dashboard/Dashboard';
import { Login } from 'views/Login/Login';
import { SetPassword } from 'views/SetPassword/SetPassoword';
import { UserManagement } from 'views/UserManagement/UserManagement';
import { ConfigSection } from './components/ConfigComponents/ConfigSection';
import { EmployeesManagement } from './views/Employees/EmployeesManagement';
import Auth0Callback from './auth/Auth0Callback';
import { NotificationsPush } from './components/NotificationsPush/NotificationsPush';
import { ComplaintMailbox } from './views/ComplaintMailbox/ComplaintMailbox';
import { Authorizations } from './views/Authorizations/Authorizations';
import ArchiveComplaintMailbox from './views/ArchiveComplaintMailbox/ArchiveComplaintMailbox';

export const Router: FC = () => {
  return (
    <Routes>
      <Route path={AppRoute.LogIn} element={<Login />} />
      <Route path={AppRoute.SetPassword} element={<SetPassword />} />
      <Route path={AppRoute.Auth0Callback} element={<Auth0Callback />} />

      <Route
        path={AppRoute.ComplaintMailbox}
        element={
          <ProtectedRoute>
            <ComplaintMailbox />
          </ProtectedRoute>
        }
      />

      <Route
        path={AppRoute.Dashboard}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path={AppRoute.Admins}
        element={
          <ProtectedRoute>
            <UserManagement />
          </ProtectedRoute>
        }
      />

      <Route
        path={AppRoute.Employees}
        element={
          <ProtectedRoute>
            <EmployeesManagement />
          </ProtectedRoute>
        }
      />

      <Route
        path={AppRoute.Config}
        element={
          <ProtectedRoute>
            <ConfigSection />
          </ProtectedRoute>
        }
      />

      <Route
        path={AppRoute.Notifications}
        element={
          <ProtectedRoute>
            <NotificationsPush />
          </ProtectedRoute>
        }
      />

      <Route
        path={AppRoute.Authorizations}
        element={
          <ProtectedRoute>
            <Authorizations />
          </ProtectedRoute>
        }
      />

      <Route
        path="*"
        element={
          <>
            <Text.h2>{intl.translate({ id: 'Error 404' })}</Text.h2>

            <Text.p>
              {intl.translate({
                id: 'La página no se encuentra disponible',
                values: {
                  a: (label: any) => <a href={AppRoute.LogIn}>{label}</a>,
                },
              } as any)}
            </Text.p>
          </>
        }
      />

      <Route
        path={AppRoute.ArchiveComplaintMailBox}
        element={
          <ProtectedRoute>
            <ArchiveComplaintMailbox />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
