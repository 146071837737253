import { StorageItem } from 'enums/storage-item.enum';
import { storage } from 'helpers/storage.helpers';
import { Locale } from './enums/locale.enum';

export function generalizeLocale(value: string): Locale {
  return value.slice(0, 2) as unknown as Locale;
}

export function setDefaultLocale(locale: Locale): void {
  storage.local.set(StorageItem.LOCALE, locale);
}

export function getDefaultLocale(): Locale {
  const storedLocale = storage.local.get<Locale>(StorageItem.LOCALE);

  if (storedLocale) {
    return storedLocale;
  }

  const locale = generalizeLocale(navigator.language);

  storage.local.set(StorageItem.LOCALE, locale);
  return locale as Locale;
}

export function firstLetterUppercase(text: string): string {
  if (!text) {
    return '';
  }
  const firstLetterUppercase = text.charAt(0).toUpperCase();
  const restOfLetters = text.slice(1).toLowerCase();
  return firstLetterUppercase + restOfLetters;
}

export function moneyFormat(number: number | string): string {
  if(typeof number === 'string') {
    number = parseInt(number);
  }
  const formato = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 0,
      maximumFractionDigits: number % 1 === 0 ? 0 : 2,
  });
  return formato.format(number);
}

export function trimStringToMaxLength(text: string, maxLength = 62) {
  if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
  }
  return text;
}