/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'utilities/http/http';
import { MonthCounterResponseDTO } from '../../types/month-counter-response.dto';
import { EmployeeByAreaResponseDTO } from '../../types/employee-by-area-response.dto';
import { RegisterByMonthDTO } from '../../types/register-by-month.dto';
import { DeactivatedByMonthDTO } from '../../types/deactivated-by-month.dto';

const baseUrl = '/company';

const getLoginsByMonthUrl = () => `${baseUrl}/dashboard/login-by-months`;
const getCoveredEmployeesUrl = () => `${baseUrl}/dashboard/covered-employees`;
const getAppLayoutAllowedUrl = () => `${baseUrl}/app-layout-allowed`;
const getEnrolledEmployeesUrl = () => `${baseUrl}/dashboard/enrolled-employees`;
const getEmployeesByAreaUrl = () => `${baseUrl}/dashboard/employees-by-area`;
const getRegisterByMonthUrl = () => `${baseUrl}/dashboard/register-by-month`;
const getDeactivatedByMonthUrl = () => `${baseUrl}/dashboard/deactivated-by-month`;
const getClicksChartUrl = () => `${baseUrl}/dashboard/clicks-chart`;
const getClickByServiceUrl = () => `${baseUrl}/dashboard/clicks-by-service`;
const getCompanyHeadCountUrl = () => `${baseUrl}/dashboard/headcount`;
const getCompanyHeadCountByAgeUrl = () => `${baseUrl}/dashboard/headcount-by-age`;
const getListProfilesUrl = (companyId: string) => `${baseUrl}/profileList?companyId=${companyId}`;
const getConsultingScoreUrl = () => `${baseUrl}/dashboard/consulting-score`;

export async function getLoginsByMonth(): Promise<MonthCounterResponseDTO[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getLoginsByMonthUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getLoginsByMonth');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getCoveredEmployees() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getCoveredEmployeesUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getCoveredEmployees');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getAppLayoutAllowed() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getAppLayoutAllowedUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getAppLayoutAllowed');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getEnrolledEmployees() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getEnrolledEmployeesUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getEnrolledEmployees');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getEmployeesByArea(): Promise<EmployeeByAreaResponseDTO[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getEmployeesByAreaUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getEmployeesByArea');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getRegisterByMonth(): Promise<RegisterByMonthDTO[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getRegisterByMonthUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getRegisterByMonth');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getDeactivatedByMonth(): Promise<DeactivatedByMonthDTO[]> {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getDeactivatedByMonthUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getDeactivatedByMonth');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getClicksChart() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getClicksChartUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getClicksChart');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getClickByService() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getClickByServiceUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getClickByService');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getCompanyHeadCount() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getCompanyHeadCountUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getCompanyHeadCount');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getCompanyHeadCountByAge() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getCompanyHeadCountByAgeUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getCompanyHeadCountByAge');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getListProfiles(companyId: string) {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getListProfilesUrl(companyId));
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getListProfiles getListProfiles');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getConsultingScore() {
  try {
    const response = await http.get<{
      success: boolean;
      data: any;
    }>(getConsultingScoreUrl());
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error getConsultingScore');
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}
