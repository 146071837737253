import {
  Box, Button,
  FormControl,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Align } from 'layout';
import { FC, useState, useEffect } from 'react';
import { StyledBoxWithPadding, StyledTextH2 } from '../../design/theme/shared-style';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import TaskCard from './TaskCard';
import {
  ComplaintPriorityEnum,
  ComplaintPrioritySpanishEnum,
  ComplaintStatus,
  ComplaintStatusEnum,
} from '../../enums/complaint.enum';
import { TitleOpen, TaskColumnStyles, TaskList, Container, titleStyles } from './complaintMailboxStyle';
import {
  getComplaintCategories,
  listComplaints,
  updateComplaintStatusAndIndex,
} from '../../api/complaint/complaint.api';
import { ComplaintDTO, ComplaintResponseDTO, ComplaintTypeDTO } from '../../types/complaint.dto';
import Skeleton from 'react-loading-skeleton';
import { ComplaintDetailModal } from '../../components/Complaint/ComplaintDetail';
import { PrioritySelectorModal } from './PrioritySelectorModal';
import { StorageItem } from '../../enums/storage-item.enum';
import { storage } from '../../helpers/storage.helpers';
import { EditResolutionModal } from './EditResolution';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ClearIcon from '@mui/icons-material/Clear';
import 'moment/locale/es';
import moment from 'moment';
import { toast } from 'react-toastify';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
moment.locale('es');
import InventoryIcon from '@mui/icons-material/Inventory';
import { exportComplaintData } from '../../components/User/Employee/Utils/xlsx.utils';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../enums/app-route.enum';

export const ComplaintMailbox: FC = () => {
  const [columns, setColumns] = useState<{
    [x: string]: {
      title: ComplaintStatus;
      items: ComplaintDTO[];
    };
  } | null>(null);
  const [showingComplaints, setShowingComplaints] = useState<ComplaintResponseDTO | null>(null);
  const [fullListComplaints, setFullListComplaints] = useState<ComplaintResponseDTO | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [complaintDetailOpen, setComplaintDetailOpen] = useState(false);
  const [prioritySelectorOpen, setPrioritySelectorOpen] = useState(false);
  const [bodyResolutionOpen, setBodyResolutionOpen] = useState(false);
  const [complaintToDetail, setComplaintToDetail] = useState<ComplaintDTO | null>(null);
  const [priority, setPriority] = useState('');
  const [categories, setCategories] = useState<ComplaintTypeDTO[]>([]);
  const [category, setCategory] = useState('');
  const [anonymous, setAnonymous] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [readedFilter, setReadedFilter] = useState('');
  const [holdingState, setHoldingState] = useState<{
    droppableId: string,
    index: number
  } | null>(null);
  const selectedCompany: {
    entityName: string;
    complaintsEnable: boolean;
    notificationsEnable: boolean;
    id: string;
  } | null = storage.session.get(StorageItem.COMPANY_SELECTED);
  const navigate = useNavigate();

  useEffect(() => {
    loadComplaints();
  }, []);

  useEffect(() => {
    loadColumns();
  }, [showingComplaints]);


  useEffect(() => {
    if (fullListComplaints) {
      applyFilter();
    } else {
      setShowingComplaints(fullListComplaints);
    }
  }, [priority, category, fullListComplaints, anonymous, endDate, startDate, readedFilter]);
  useEffect(() => {
    loadCategoriesDropwdown();
  }, []);

  const applyFilter = () => {
    let filteredComplaints = { ...fullListComplaints };
    if (priority) {
      filteredComplaints = Object.fromEntries(
        Object.entries(filteredComplaints).map(([key, value]) => [
          key,
          value?.filter(complaint => complaint.priority === priority),
        ]),
      );
    }
    if (category) {
      filteredComplaints = Object.fromEntries(
        Object.entries(filteredComplaints).map(([key, value]) => [
          key,
          value?.filter(complaint => complaint.type.name === category),
        ]),
      );
    }
    if (anonymous) {
      if (anonymous === 'anonymous') {
        filteredComplaints = Object.fromEntries(
          Object.entries(filteredComplaints).map(([key, value]) => [
            key,
            value?.filter(complaint => complaint.anonymous),
          ]),
        );
      } else if (anonymous === 'notAnonymous') {
        filteredComplaints = Object.fromEntries(
          Object.entries(filteredComplaints).map(([key, value]) => [
            key,
            value?.filter(complaint => !complaint.anonymous),
          ]),
        );
      }
    }
    if (startDate) {
      const normalizedStartDate = new Date(startDate);
      normalizedStartDate.setHours(0, 0, 0, 0);
      filteredComplaints = Object.fromEntries(
        Object.entries(filteredComplaints).map(([key, value]) => [
          key,
          value?.filter(complaint => {
            const complaintDate = new Date((complaint as any).createdAt);
            complaintDate.setHours(0, 0, 0, 0);
            return complaintDate >= normalizedStartDate;
          }),
        ]),
      );
    }
    if (endDate) {
      const normalizedStartDate = new Date(endDate);
      normalizedStartDate.setHours(0, 0, 0, 0);
      filteredComplaints = Object.fromEntries(
        Object.entries(filteredComplaints).map(([key, value]) => [
          key,
          value?.filter(complaint => {
            const complaintDate = new Date((complaint as any).createdAt);
            complaintDate.setHours(0, 0, 0, 0);
            return complaintDate <= normalizedStartDate;
          }),
        ]),
      );
    }
    if(readedFilter === 'readed') {
      filteredComplaints = Object.fromEntries(
        Object.entries(filteredComplaints).map(([key, value]) => [
          key,
          value?.filter(complaint => complaint.readed),
        ]),
      );
    }
    if(readedFilter === 'notReaded') {
      filteredComplaints = Object.fromEntries(
        Object.entries(filteredComplaints).map(([key, value]) => [
          key,
          value?.filter(complaint => !complaint.readed),
        ]),
      );
    }
    setShowingComplaints(filteredComplaints);
  }

  const loadColumns = () => {
    const columnsStatus = {
      [ComplaintStatusEnum.OPEN]: {
        title: ComplaintStatus.OPEN,
        items: showingComplaints?.OPEN || [],
      },
      [ComplaintStatusEnum.INVESTIGATION]: {
        title: ComplaintStatus.INVESTIGATION,
        items: showingComplaints?.INVESTIGATION || [],
      },
      [ComplaintStatusEnum.PROGRESS]: {
        title: ComplaintStatus.PROGRESS,
        items: showingComplaints?.PROGRESS || [],
      },
      [ComplaintStatusEnum.CLOSE]: {
        title: ComplaintStatus.CLOSE,
        items: showingComplaints?.CLOSE || [],
      },
    };
    setColumns(columnsStatus);
  };

  const updateCurrentComplaintToDetail = (complaintsList: ComplaintResponseDTO) => {
    let foundComplaint: ComplaintDTO | undefined;
    for (const key of Object.keys(complaintsList) as (keyof ComplaintResponseDTO)[]) {
      const complaints = complaintsList[key];
      if (complaints) {
        foundComplaint = complaints.find(complaint => complaint.id === complaintToDetail?.id);
        if (foundComplaint) {
          break;
        }
      }
    }
    if (foundComplaint) {
      setComplaintToDetail(foundComplaint);
    }
  };

  const loadComplaints = async () => {
    const complaintsList = await listComplaints();
    setShowingComplaints(complaintsList);
    setFullListComplaints(complaintsList);
    if (complaintToDetail) {
      updateCurrentComplaintToDetail(complaintsList);
    }
    setIsLoading(false);
  };

  const openComplaintDetail = (item: ComplaintDTO) => {
    setComplaintDetailOpen(true);
    setComplaintToDetail(item);
  };

  const updateColumnNewStatus = (source: any, destination: any, columns: any): boolean => {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    if (source.droppableId === ComplaintStatusEnum.OPEN && !removed.priority) {
      setHoldingState(destination);
      setPrioritySelectorOpen(true);
      setComplaintToDetail(removed);
      return false;
    }
    if (destination.droppableId === ComplaintStatusEnum.CLOSE && !removed.bodyResolution) {
      setHoldingState(destination);
      setComplaintToDetail(removed);
      setBodyResolutionOpen(true);
      return false;
    }
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
    return true;
  };

  const updateColumnSameStatus = (source: any, destination: any, columns: any) => {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  };

  const onDragEnd = (result: any, columns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    let letUpdate = true;
    if (source.droppableId !== destination.droppableId) {
      letUpdate = updateColumnNewStatus(source, destination, columns);
    } else {
      updateColumnSameStatus(source, destination, columns);
    }
    if (letUpdate) {
      updateComplaintStatusAndIndex({
        id: result.draggableId,
        newStatus: destination.droppableId,
        index: destination.index,
      });
    }
  };
  const loadCategoriesDropwdown = async (): Promise<void> => {
    const complaintCategories = await getComplaintCategories();
    setCategories(complaintCategories);
  };
  const handleClearStartDate = () => {
    setStartDate(null);
  };
  const handleClearEndDate = () => {
    setEndDate(null);
  };
  const handleStartDateFilter = (newValue: Date | null) => {
    if(newValue && (!endDate || newValue < endDate)) {
      setStartDate(newValue);
    } else if(endDate && newValue && newValue > endDate) {
      toast.error('La fecha “Desde“ debe ser anterior a la fecha “Hasta“.', {
        position: 'top-center',
      });
      setStartDate(null);
    }
  };

  const handleEndDateFilter = (newValue: Date | null) => {
    if(newValue && (!startDate || newValue > startDate)) {
      setEndDate(newValue);
    } else if(startDate && newValue && newValue < startDate) {
      toast.error('La fecha “Hasta” debe ser posterior a la fecha “Desde”.', {
        position: 'top-center',
      });
      setEndDate(null);
    }
  };

  const goToArchive = () => {
    navigate(AppRoute.ArchiveComplaintMailBox);
  };
  return (
    <StyledBoxWithPadding>
      {selectedCompany && selectedCompany.complaintsEnable && (
        <>
          <Box mx={0} my={2} p={0}>
            <StyledTextH2>
              Buzón de Denuncias
            </StyledTextH2>
          </Box>
          <div style={{marginBottom: '26px'}}>
            <Button
              startIcon={<InventoryIcon />}
              variant="outlined"
              style={{marginRight: '10px'}}
              onClick={goToArchive}
            >
              Denuncias archivadas
            </Button>
            <Button
              style={{ borderColor: '#fd7e14', color: '#fd7e14' }}
              variant="outlined"
              startIcon={<BrowserUpdatedIcon />}
              onClick={() => exportComplaintData(showingComplaints)}
            >
              Exportar
            </Button>
          </div>
          <div style={{ marginBottom: '30px', display: 'flex' }}>
            <FormControl style={{ width: '10%', minWidth: '118px' }}>
              <InputLabel>Prioridad</InputLabel>
              <Select value={priority} label="Prioridad"
                      onChange={(e) => setPriority(e.target.value)}>
                <MenuItem key="ALL" value="">
                  <em>Todas</em>
                </MenuItem>
                <MenuItem key={ComplaintPriorityEnum.HIGH}
                          value={ComplaintPriorityEnum.HIGH}>{ComplaintPrioritySpanishEnum.HIGH}
                </MenuItem>
                <MenuItem key={ComplaintPriorityEnum.MEDIUM}
                          value={ComplaintPriorityEnum.MEDIUM}>{ComplaintPrioritySpanishEnum.MEDIUM}
                </MenuItem>
                <MenuItem key={ComplaintPriorityEnum.LOW}
                          value={ComplaintPriorityEnum.LOW}>{ComplaintPrioritySpanishEnum.LOW}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: '15%', marginLeft: '16px', minWidth: '118px' }}>
              <InputLabel>Categoría</InputLabel>
              <Select value={category} label="Categoría"
                      onChange={(e) => setCategory(e.target.value)}>
                <MenuItem key="ALL" value="">
                  <em>Todas</em>
                </MenuItem>
                {
                  categories.map((category) => {
                    return (
                      <MenuItem key={category.id.toString()} value={category.name}>
                        {category.name}
                      </MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
            <FormControl style={{ width: '15%', marginLeft: '16px', minWidth: '118px' }}>
              <InputLabel>Identidad</InputLabel>
              <Select value={anonymous} label="Identidad"
                      onChange={(e) => setAnonymous(e.target.value)}>
                <MenuItem key="ALL" value="">
                  <em>Todos</em>
                </MenuItem>
                <MenuItem key="ALL" value="anonymous">
                  Anónimo
                </MenuItem>
                <MenuItem key="ALL" value="notAnonymous">
                  Identificado
                </MenuItem>
              </Select>
            </FormControl>
            <div style={{ marginLeft: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box display="flex" alignItems="center" >
                  <Box display="flex" alignItems="center" mr={2} style={{width: '52%', minWidth: '207px', maxWidth: '240px'}}>
                    <DatePicker
                      label="Creada desde"
                      value={startDate}
                      inputFormat="DD/MM/YYYY"
                      onChange={handleStartDateFilter}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {startDate && (
                                  <InputAdornment position="end">
                                    <Grow in={true} timeout={900}>
                                      <IconButton
                                        onClick={handleClearStartDate}
                                        aria-label="Limpiar creado desde"
                                        edge="end"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </Grow>
                                  </InputAdornment>
                                )}
                                {params.InputProps?.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box display="flex" alignItems="center" style={{width: '52%', minWidth: '207px', maxWidth: '240px'}}>
                    <DatePicker
                      label="Creada hasta"
                      value={endDate}
                      inputFormat="DD/MM/YYYY"
                      onChange={handleEndDateFilter}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {endDate && (
                                  <InputAdornment position="end">
                                    <Grow in={true} timeout={900}>
                                      <IconButton
                                        onClick={handleClearEndDate}
                                        aria-label="Limpiar creado hasta"
                                        edge="end"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </Grow>
                                  </InputAdornment>
                                )}
                                {params.InputProps?.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </LocalizationProvider>
            </div>
            <FormControl style={{ width: '15%', marginLeft: '16px', minWidth: '118px' }}>
              <InputLabel>Estado</InputLabel>
              <Select value={readedFilter} label="Estado"
                      onChange={(e) => setReadedFilter(e.target.value)}>
                <MenuItem key="ALL" value="">
                  <em>Todos</em>
                </MenuItem>
                <MenuItem key="ALL" value="readed">
                  Leído
                </MenuItem>
                <MenuItem key="ALL" value="notReaded">
                  No leído
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Align v-center>
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, columns)}
              >
                <Container>
                  <TaskColumnStyles>
                    {columns && Object.entries(columns).map(([columnId, column], index) => {
                      const TitleComponent = titleStyles[column.title] || TitleOpen;
                      return (
                        <Droppable key={columnId} droppableId={columnId}>
                          {(provided, snapshot) => (
                            <TaskList
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              style={{
                                boxShadow: '3px 3px 8px 1px rgba(0, 0, 0, 0.1)',
                              }}
                            >
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TitleComponent style={{
                                  fontSize: '20px',
                                  fontWeight: '600',
                                  marginBottom: '10px',
                                }}>
                                  {column.title}
                                </TitleComponent>
                              </div>
                              {!isLoading ? column.items?.map((item, index) => (
                                <TaskCard open={openComplaintDetail}
                                          key={item as unknown as React.Key | null | undefined}
                                          item={item} index={index} />
                              )) : <Skeleton style={{
                                height: '100px',
                                marginTop: '15px',
                              }} />}
                              {provided.placeholder}
                            </TaskList>
                          )}
                        </Droppable>
                      );
                    })}
                  </TaskColumnStyles>
                </Container>
              </DragDropContext>
            </Align>
          </div>
          {complaintToDetail && (
            <ComplaintDetailModal
              open={complaintDetailOpen}
              complaintToDetail={complaintToDetail}
              onClose={() => setComplaintDetailOpen(false)}
              reloadComplaints={loadComplaints}
            />
          )}
          {complaintToDetail && (
            <PrioritySelectorModal
              open={prioritySelectorOpen}
              onClose={() => {
                setPrioritySelectorOpen(false);
                setComplaintToDetail(null);
              }}
              complaintToDetail={complaintToDetail}
              reloadComplaints={loadComplaints}
              holdingState={holdingState}
            />
          )}
          {complaintToDetail && (
            <EditResolutionModal
              open={bodyResolutionOpen}
              onClose={() => setBodyResolutionOpen(false)}
              complaintToDetail={complaintToDetail}
              reloadComplaints={loadComplaints}
              holdingState={holdingState}
            />
          )}
        </>)
      }
    </StyledBoxWithPadding>
  );
};
