import { http } from "../../utilities/http/http";

const baseUrl = '/doc123';

const getHealthDataServiceUrl = () => `${baseUrl}/chart-report`;
const getSpecialityCountDataUrl = () => `${baseUrl}/speciality-twelve-months`;

export async function getHealthDataService(): Promise<{
    specialty: {
        consultingReasonSpeciality: {
            month: string;
            consultingCount: {
                reason: string;
                count: number;
            }[];
        }[];
    };
    cie10: {
        countByMonth: {
            month: string;
            counter: number;
        }[];
        countConsultingReasonLastMonth: {
            consulting_reason: string;
            counter: number;
        }[];
    };
}> {
    try {
        const response = await http.get<{
            success: boolean;
            data: any;
        }>(getHealthDataServiceUrl());
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error');
        }
    } catch (err: any) {
        console.log(err);
        throw new Error(err.message);
    }
}

export async function getSpecialityCountData(): Promise<any> {
    try {
        const response = await http.get<{
            success: boolean;
            data: any;
        }>(getSpecialityCountDataUrl());
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error getSpecialityCountData');
        }
    } catch (err: any) {
        console.log(err);
        throw new Error(err.message);
    }
}