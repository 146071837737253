import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { MRT_ColumnDef } from 'material-react-table';
import { FC, useEffect, useState } from 'react';
import { DateInput } from '../../../design/input/DateTime/DateInput';
import { UserPaymentFrequencyEnum, UserSex } from '../../../enums/user-enum';
import { Profile, User } from '../../../types/user.dto';
import { selectUser } from '../../../global-state/selectors';
import { useSelector } from 'react-redux';
import { isUserFormValid, salaryOnDemandIsSelected } from '../../../helpers/functional.helpers';
import { storage } from '../../../helpers/storage.helpers';
import { StorageItem } from '../../../enums/storage-item.enum';
import { CompanyList } from '../../AppBar/SideNavBar';
import {
  AppLayoutOption, CreateFormValues,
  emptyNewEmployeeForm, formatMobileChange,
  getBanks,
  hasProfileOrContract,
  loadAppLayoutAllowed,
} from './Utils/employee.utils';

export const CreateEmployeeModal: FC<{
  columns: MRT_ColumnDef<User>[];
  onClose: () => void;
  onSubmit: (values: any) => void;
  open: boolean;
  profileList?: Profile[] | null;
}> = ({ open, columns, onClose, onSubmit, profileList }) => {
  const emptyForm: CreateFormValues = emptyNewEmployeeForm();
  const [values, setValues] = useState(emptyForm);
  const [banks, setBanks] = useState<any[]>([]);
  const loggedUser = useSelector(selectUser);
  const companySelectedStorange = storage.session.get(StorageItem.COMPANY_SELECTED) as CompanyList;
  const [appLayoutOptions, setAppLayoutOptions] = useState<AppLayoutOption[]>([]);
  const enumValues = (enumme: any) => Object.keys(enumme).map(key => enumme[key]);
  const [isSalaryOnDemandSelected, setIsSalaryOnDemandSelected] =
    useState<boolean>(!!companySelectedStorange.contractData?.activeContract);
  const handleMobileChange = (value: string) => setValues({ ...values, mobile: formatMobileChange(value) });
  const loadBanks = async () => setBanks(await getBanks());

  const handleAppLayoutChange = (event: SelectChangeEvent<string>) => {
    if (salaryOnDemandIsSelected(event.target.value)) {
      setIsSalaryOnDemandSelected(true);
    } else {
      setIsSalaryOnDemandSelected(false);
    }
    setValues({ ...values, appLayout: event.target.value })
  };

  const loadAppLayout = async () => {
    if (!hasProfileOrContract(profileList, companySelectedStorange)) {
      const loadedAppLayoutOptions = await loadAppLayoutAllowed(loggedUser);
      setAppLayoutOptions(loadedAppLayoutOptions);
    }
  };

  const handleSubmit = () => {
    if (!isUserFormValid(isSalaryOnDemandSelected, values, (!!profileList || !!companySelectedStorange.contractData.activeContract))) {
      return;
    }
    onSubmit(values);
  };

  const handlerClose = () => {
    onClose();
    setValues(emptyForm);
  };

  useEffect(() => {
    loadBanks();
  }, []);

  useEffect(() => {
    setIsSalaryOnDemandSelected(hasProfileOrContract(profileList, companySelectedStorange));
  }, [profileList]);

  useEffect(() => {
    loadAppLayout();
  }, []);

  useEffect(() => {
    setValues(emptyForm);
  }, [open]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle textAlign="left">
        Crear nuevo colaborador
        <CloseDialogButton onClose={handlerClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>

          <Typography variant="h6" sx={{ mt: 2 }} style={{ marginBottom: '16px' }}>Persona</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <TextField label="Nombre*" name="name" value={values.name}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <TextField label="Apellido Materno*" name="secondLastname" value={values.secondLastname}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <TextField label="RFC*" name="rfc" value={values.rfc}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <TextField label="Correo electrónico*" name="email" value={values.email}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <DateInput label="Fecha de nacimiento*" name="birthday" value={values.birthday}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <TextField label="Apellido Paterno*" name="firstLastname" value={values.firstLastname}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <FormControl fullWidth>
                  <InputLabel id="select-sex-label">Sexo*</InputLabel>
                  <Select labelId="select-sex-label" id="select-sex" value={values.sex} name="sex" label="Sexo*"
                    onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}>
                    <MenuItem value={UserSex.Masculine}>Masculino</MenuItem>
                    <MenuItem value={UserSex.Feminine}>Femenino</MenuItem>
                  </Select>
                </FormControl>
                <TextField label="CURP*" name="curp" value={values.curp}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <TextField label="Teléfono" name="mobile" value={values.mobile}
                  onChange={(e) => handleMobileChange(e.target.value)} inputProps={{ maxLength: 16 }} />
              </Stack>
            </Grid>
          </Grid>

          <Typography variant="h6" style={{ marginBottom: '16px' }} sx={{ mt: 2 }}>Empresa</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <TextField label="Número de empleado" name="companyEmployeeNumber" value={values.companyEmployeeNumber}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <TextField label="Área" name="area" value={values.area}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <DateInput label={isSalaryOnDemandSelected ? 'Fecha de ingreso*' : 'Fecha de ingreso'} 
                  name="startDate" value={values.startDate}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <TextField label="Puesto" name="position" value={values.position}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <TextField label="Ubicación" name="location" value={values.location}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <TextField label="Correo electrónico empresarial" name="companyEmail" value={values.companyEmail}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
              </Stack>
            </Grid>
          </Grid>

          <Typography variant="h6" style={{ marginBottom: '16px' }} sx={{ mt: 2 }}>Pago</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <TextField label={isSalaryOnDemandSelected ? 'Salario Neto*' : 'Salario Neto'} name="netSalary" value={values.netSalary}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
                <TextField label={isSalaryOnDemandSelected ? 'CLABE*' : 'CLABE'} name="clabe" value={values.clabe}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <InputLabel id="select-frequency">{isSalaryOnDemandSelected ? 'Frecuencia de pago*' : 'Frecuencia de pago'}</InputLabel>
                  <Select labelId="payment-frequency" id="payment-frequency" value={values.paymentFrequency || ''} name="paymentFrequency"
                    onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                    label={isSalaryOnDemandSelected ? 'Frecuencia de pago*' : 'Frecuencia de pago'}>
                    {enumValues(UserPaymentFrequencyEnum).map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>{isSalaryOnDemandSelected ? 'Banco*' : 'Banco'}</InputLabel>
                  <Select value={values.bank || ''} name="bank" label={isSalaryOnDemandSelected ? 'Banco*' : 'Banco'}
                    onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}>
                    {banks.map((bank) => <MenuItem key={bank.code} value={bank.code}>{bank.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>

          <Typography variant="h6" style={{ marginBottom: '16px' }} sx={{ mt: 2 }}>Twispi</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {
                  (companySelectedStorange.contractData?.relatedProfiles
                    && companySelectedStorange.contractData.relatedProfiles?.length > 0) ?
                    <>
                      <InputLabel id="profile-select-label">Perfil de servicio*</InputLabel>
                      <Select labelId="profile-select-label" id="profile" value={values.profile || ''} label="Perfil de servicio"
                              onChange={(event) => setValues({ ...values, profile: event.target.value })}>
                        {
                          companySelectedStorange.contractData.relatedProfiles.map((option) => {
                            return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                          })
                        }
                      </Select>
                    </> :
                    (profileList && profileList.length > 0) ?
                      <>
                        <InputLabel id="profile-select-label">Perfil de servicio*</InputLabel>
                        <Select labelId="profile-select-label" id="profile" value={values.profile || ''} label="Perfil de servicio"
                                onChange={(event) => setValues({ ...values, profile: event.target.value })}>
                          {
                            profileList.map((option) => {
                              return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                            })
                          }
                        </Select>
                      </> :
                      <>
                        <InputLabel id="app-layout-select-label">Servicios disponibles*</InputLabel>
                        <Select labelId="app-layout-select-label" id="appLayout" value={values.appLayout || ''} label="Servicios disponibles"
                                onChange={handleAppLayoutChange}>
                          {appLayoutOptions.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                        </Select>
                      </>
                }
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={handlerClose}>Cancelar</CancelButton>
        <ConfirmButton handleClick={handleSubmit}><strong>Guardar</strong></ConfirmButton>
      </DialogActions>
    </Dialog>
  );
}
