/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmployeeCreateDTO } from 'types/employee-create.dto';
import { User } from 'types/user.dto';
import { http } from 'utilities/http/http';
const baseUrl = '/user';

const createEmployeeUrl = () => `${baseUrl}/employee`;
const createEmployeeAdminUrl = () => `${baseUrl}/employeeAdmin`;
const createEmployeesFromXMLUrl = () => `${baseUrl}/employeesFromXML`;
const createEmployeesFromXMLAdminUrl = () => `${baseUrl}/employeesFromXMLAdmin`;
const editEmployeeUrl = (id: string) => `${baseUrl}/employee/${id}`;
const activateEmployeeUrl = (id: string) => `${baseUrl}/employeeActivate/${id}`;
const deactivateEmployeeUrl = (id: string) => `${baseUrl}/employeeDeactivate/${id}`;
const editEmployeesFromXMLUrl = () => `${baseUrl}/editEmployeesFromXML`;

export async function createEmployee(
  employee: EmployeeCreateDTO,
): Promise<User> {
  try {
    const response = await http.post<{
      success: boolean;
      data: User;
    }>(createEmployeeUrl(), employee);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Create Employee');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function createEmployeeAdmin(
  employee: EmployeeCreateDTO,
): Promise<User> {
  try {
    const response = await http.post<{
      success: boolean;
      data: User;
    }>(createEmployeeAdminUrl(), employee);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Create Employee Admin');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function createEmployeesFromXML(
  employees: EmployeeCreateDTO[],
): Promise<any> {
  try {
    const response = await http.post<{
      success: boolean;
      data: User;
    }>(createEmployeesFromXMLUrl(), employees);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Create Employees from XML');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function editEmployeesFromXML(
  employees: EmployeeCreateDTO[],
): Promise<number> {
  try {
    const response = await http.post<{
      success: boolean;
      data: number;
    }>(editEmployeesFromXMLUrl(), employees);
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Edit Employees from XML');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function createEmployeesFromXMLAdmin(employeesData: {
  selectedCompanyId: string;
  employees: EmployeeCreateDTO[];
}): Promise<any> {
  try {
    const response = await http.post<{
      success: boolean;
      data: User;
    }>(createEmployeesFromXMLAdminUrl(), employeesData);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Create Employees from XML Admin');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function editEmployee(
  id: string,
  user: EmployeeCreateDTO,
): Promise<User> {
  try {
    const response = await http.put<{
      success: boolean;
      data: User;
    }>(editEmployeeUrl(id), user);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Edit User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}
export async function activateEmployee(id: string): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>(activateEmployeeUrl(id))

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Activate Employee');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function deactivateEmployee(id: string, endData: {
  endDate: Date,
  endReason: string
}): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>(deactivateEmployeeUrl(id), endData);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Deactivate Employee');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}
