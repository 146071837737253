import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { CloseDialogButton } from '../../design/Button/CloseDialogButton';
import { TextInput } from 'design';
import { CancelButton } from '../Shared/Buttons/CancelButton';
import { ConfirmButton } from '../Shared/Buttons/ConfirmButton';
import { InputChangeEvent } from '../../design/types/input-change-event';
import { toast } from 'react-toastify';
import { UserRoleEnum } from '../../types/user-role.enum';
import { selectUser } from '../../global-state/selectors';
import { useSelector } from 'react-redux';
import { StyledTextInfo, Textarea, Title } from '../../design/theme/shared-style';
import { NewNotificationDTO } from '../../types/notifications.dto';
import { LastLoginValue, SelectedValue } from '../../enums/dropdown.enum';
import MultiCompaniesDropDown from '../User/Employee/MultiCompanyDropdown';
import { storage } from '../../helpers/storage.helpers';
import { StorageItem } from '../../enums/storage-item.enum';
import { Company, Profile } from '../../types/user.dto';
import { ModalToken } from '../Shared/ModalToken/ModalToken';
import { UserStatusEnum } from '../../types/user-status.enum';

export const NewNotificationModal: FC<{
  locationToSelect: string[],
  areaToSelect: string[],
  profileToSelect: Profile[],
  open: boolean,
  onClose: () => void,
  onSubmit: (values: NewNotificationDTO) => void,
  companies: any[],
  updateSelectorHanlder: (companySelected: string) => void
}> = ({
        locationToSelect,
        areaToSelect,
        open,
        onClose,
        onSubmit,
        companies,
        updateSelectorHanlder,
        profileToSelect,
      }) => {
  const [areaOptions, setAreaOptions] = useState<string[]>(areaToSelect);
  const [locationOptions, setLocationOptions] = useState<string[]>(locationToSelect);
  const [notificationTitle, setNotificationTitle] = useState<string>('');
  const [notificationBody, setNotificationBody] = useState<string>('');
  const [area, setArea] = useState<string>(SelectedValue.ALL);
  const [location, setLocation] = useState<string>(SelectedValue.ALL);
  const loggedUser = useSelector(selectUser);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string[]>([]);
  const [tokenModalOpen, setTokenModalOpen] = useState<boolean>(false);
  const [lastLoginDate, setLastLoginDate] = useState<string>(SelectedValue.ALL);
  const [userStatus, setUserStatus] = useState<string>(SelectedValue.ALL);
  const [profile, setProfile] = useState<string>(SelectedValue.ALL);

  const setCompaniesFromDropDown = async (ids: string[]) => {
    const hasCompaniesSelected = ids.length > 0 && ids[0] !== 'all';
    if (hasCompaniesSelected) {
      setSelectedCompanyId(ids);
      updateSelectorHanlder(ids[0]);
    } else {
      setSelectedCompanyId([]);
    }
    setArea(SelectedValue.ALL);
    setLocation(SelectedValue.ALL);
  };

  const checkInputs = () => {
    if (!notificationTitle) {
      toast.error('El título es requerido.');
      return false;
    }
    if (!notificationBody) {
      toast.error('El mensaje es requerido.');
      return false;
    }
    if (!area) {
      toast.error('El área es requerida.');
      return false;
    }
    if (!location) {
      toast.error('La Ubicación es requerida.');
      return false;
    }
    return true;
  };

  const submitCompanyNotification = () => {
    const companySelectedStorange: Company | null = storage.session.get(StorageItem.COMPANY_SELECTED);
    const currentCompanyId: string[] = [];
    currentCompanyId.push(companySelectedStorange?.id || '');
    onSubmit({
      notificationBody,
      area,
      location,
      notificationTitle,
      selectedCompanyId: currentCompanyId,
      profile,
      lastLoginDate,
      userStatus: userStatus as UserStatusEnum
    });
  };

  const submitAdminNotification = () => {
    onSubmit({
      notificationBody,
      area,
      location,
      notificationTitle,
      selectedCompanyId,
      profile,
      lastLoginDate,
      userStatus: userStatus as UserStatusEnum
    });
  };

  const handleSubmit = () => {
    if (loggedUser?.role === UserRoleEnum.ADMIN) {
      submit();
    } else {
      setTokenModalOpen(true);
    }
  };

  const submit = () => {
    const validInputs = checkInputs();
    if (!validInputs) {
      return;
    }
    if (loggedUser?.role === UserRoleEnum.ADMIN) {
      submitAdminNotification();
    } else {
      submitCompanyNotification();
    }
    resetForm();
  };
  const handleAreaChange = (event: SelectChangeEvent<string>) => {
    setArea(event.target.value);
  };
  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setLocation(event.target.value);
  };
  const handleUserStatusChange = (event: SelectChangeEvent<string>) => {
    setUserStatus(event.target.value);
  };
  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const maxCharactersBody = 181;
    if (notificationBody.length < maxCharactersBody || event.target.value.length < notificationBody.length) {
      setNotificationBody(event.target.value);
    }
  };
  const handleTitleChange = (event: InputChangeEvent<string>) => {
    const maxCharactersTitle = 40;
    if (notificationTitle.length < maxCharactersTitle || event.target.value.length < notificationTitle.length) {
      setNotificationTitle(event.target.value);
    }
  };
  const resetForm = () => {
    setArea(SelectedValue.ALL);
    setLocation(SelectedValue.ALL);
    setLastLoginDate(SelectedValue.ALL);
    setUserStatus(SelectedValue.ALL);
    setProfile(SelectedValue.ALL);
    setNotificationBody('');
    setNotificationTitle('');
    setSelectedCompanyId([]);
  };
  const handlerClose = () => {
    onClose();
    resetForm();
  };
  const handlelastLoginDateFilter = (event: SelectChangeEvent<string>) => {
    setLastLoginDate(event.target.value);
  };
  const handleProfileFilter = (event: SelectChangeEvent<string>) => {
    setProfile(event.target.value);
  };
  useEffect(() => {
    setAreaOptions(areaToSelect);
  }, [areaToSelect]);
  useEffect(() => {
    setLocationOptions(locationToSelect);
  }, [locationToSelect]);

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle textAlign="left" mb={1} style={{ marginBottom: '0px' }}>
          <Title>Enviar notificación</Title>
          <CloseDialogButton onClose={handlerClose} />
        </DialogTitle>
        <DialogContent>
          <StyledTextInfo>Este formulario te permite comunicarte con todos los colaboradores
            de tu empresa mediante mensajes que recibirán como notificaciones push en sus dispositivos
            móviles.</StyledTextInfo>
          {loggedUser?.role === UserRoleEnum.ADMIN &&
            companies &&
            companies.length > 0 && (
              <div style={{ marginBottom: '25px' }}>
                <MultiCompaniesDropDown
                  companies={companies}
                  setCompaniesFromDropDown={setCompaniesFromDropDown}
                  disableMargin={true}
                  disableWidth={true}
                  defaultOptionRequired={true}
                  searchText="Seleccionar empresas"
                ></MultiCompaniesDropDown>
              </div>
            )}
          <TextInput
            label="Título"
            id="notificationTitle"
            name="notificationTitle"
            type="text"
            onChange={handleTitleChange}
            value={notificationTitle}
            variant="outlined"
            fullWidth
          />
          <Textarea minRows="3" placeholder="Escribe aquí tu mensaje" value={notificationBody}
                    onChange={handleTextChange}
                    style={{ maxWidth: '100%', width: '100%', minWidth: '100%', minHeight: '30px' }}></Textarea>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%', marginTop: '25px' }}>
              <DialogActions sx={{ p: '1.25rem' }} style={{
                justifyContent: 'space-between', paddingTop: '3px',
                paddingLeft: '0px', paddingRight: '10px',
              }}>
                <div style={{ width: '100%' }}>
                  <FormControl fullWidth
                               disabled={(loggedUser?.role === UserRoleEnum.ADMIN && (!selectedCompanyId || !selectedCompanyId[0])
                                   || (selectedCompanyId.length > 0 && selectedCompanyId[0] === 'all') || selectedCompanyId.length > 1)
                                 || !areaOptions || areaOptions.length == 0 || (areaOptions.length === 1 && areaOptions[0] === '')}>
                    <InputLabel id="app-layout-select-label">Área</InputLabel>
                    <Select
                      labelId="app-area-select-label"
                      id="area"
                      value={area || ''}
                      label="Áreas"
                      onChange={handleAreaChange}
                    >
                      <MenuItem key="Todas las areas" value={SelectedValue.ALL}>
                        Todas las áreas
                      </MenuItem>
                      {areaOptions?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </DialogActions>
            </div>
            <div style={{ width: '50%', marginTop: '25px' }}>
              <DialogActions sx={{ p: '1.25rem' }} style={{
                justifyContent: 'space-between', paddingTop: '3px',
                paddingLeft: '10px', paddingRight: '0px',
              }}>
                <div style={{ width: '100%' }}>
                  <FormControl fullWidth
                               disabled={(loggedUser?.role === UserRoleEnum.ADMIN && (!selectedCompanyId || !selectedCompanyId[0])
                                   || (selectedCompanyId.length > 0 && selectedCompanyId[0] === 'all') || selectedCompanyId.length > 1)
                                 || !locationOptions || locationOptions.length === 0 || (locationOptions.length === 1 && locationOptions[0] === '')}>
                    <InputLabel id="app-layout-select-label">Ubicación</InputLabel>
                    <Select
                      labelId="app-location-select-label"
                      id="location"
                      value={location || ''}
                      label="Ubicación"
                      onChange={handleLocationChange}
                    >
                      <MenuItem key="Todas las ubicaciones" value={SelectedValue.ALL}>
                        Todas las ubicaciones
                      </MenuItem>
                      {locationOptions?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </DialogActions>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <FormControl fullWidth
                         disabled={(loggedUser?.role === UserRoleEnum.ADMIN && (!selectedCompanyId || !selectedCompanyId[0])
                           || (selectedCompanyId.length > 0 && selectedCompanyId[0] === 'all') || selectedCompanyId.length > 1)
                           || !profileToSelect || profileToSelect.length === 0 || (profileToSelect.length === 1 && profileToSelect[0].name === '')}>
              <InputLabel id="app-layout-select-label">Perfil</InputLabel>
              <Select
                labelId="app-profile-select-label"
                id="profile"
                value={profile || ''}
                label="Perfil"
                onChange={handleProfileFilter}
              >
                <MenuItem key="Todos los perfiles" value={SelectedValue.ALL}>
                  Todos los perfiles
                </MenuItem>
                {profileToSelect?.map((option) => (
                  <MenuItem key={option?.id} value={option?.name}>
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <div style={{ width: '50%' }}>
              <DialogActions sx={{ p: '1.25rem' }} style={{
                justifyContent: 'space-between', paddingTop: '3px',
                paddingLeft: '0px', paddingRight: '10px',
              }}>
                <div style={{ width: '100%' }}>
                  <FormControl fullWidth>
                    <InputLabel id="app-layout-select-label">Estado</InputLabel>
                    <Select
                      labelId="app-status-select-label"
                      id="status"
                      value={userStatus || ''}
                      label="Estado"
                      onChange={handleUserStatusChange}
                    >
                      <MenuItem key="Todos los estados" value={SelectedValue.ALL}>
                        Todos los estados
                      </MenuItem>
                      <MenuItem key={UserStatusEnum.Active} value={UserStatusEnum.Active}>
                        Activo
                      </MenuItem>
                      <MenuItem key={UserStatusEnum.Pending} value={UserStatusEnum.Pending}>
                        Pendiente
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogActions>
            </div>
            <div style={{ width: '50%', paddingLeft: '10px' }}>
              <DialogActions sx={{ p: '1.25rem' }} style={{
                justifyContent: 'space-between', paddingTop: '3px', paddingRight: '0px',
                paddingLeft: '0px',
              }}>
                <div style={{ width: '100%' }}>
                  <FormControl fullWidth>
                    <InputLabel id="app-layout-select-label">Último inicio de sesión</InputLabel>
                    <Select
                      labelId="app-login-select-label"
                      id="login"
                      value={lastLoginDate || ''}
                      label="Último inicio de sesión"
                      onChange={handlelastLoginDateFilter}
                    >
                      <MenuItem key="Todos" value={SelectedValue.ALL}>
                        Todas las sesiones
                      </MenuItem>
                      <MenuItem key="withAccess" value={LastLoginValue.WITH_ACCESS_LAST_30_DAYS}>
                        Últimos 30 días
                      </MenuItem>
                      <MenuItem key="withoutAccess" value={LastLoginValue.WITHOUT_ACCESS_LAST_30_DAYS}>
                        Más de 30 días
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogActions>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <CancelButton handleClick={handlerClose}>Cancelar</CancelButton>
          <ConfirmButton handleClick={handleSubmit}>
            <strong>Enviar</strong>
          </ConfirmButton>
        </DialogActions>
      </Dialog>
      <ModalToken
        open={tokenModalOpen}
        onClose={() => setTokenModalOpen(false)}
        successToken={() => submit()}
      />
    </>
  );
};