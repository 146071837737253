import React, { FC, useState, useRef, useEffect } from 'react';
import {
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { CloseDialogButton } from '../../../design/Button/CloseDialogButton';
import { CancelButton } from '../Buttons/CancelButton';
import { ConfirmButton } from '../Buttons/ConfirmButton';
import { sendVerificationToken, verifyTokenService } from '../../../api/user/user.api';
import { toast } from '../../Toast/Toast';

export const ModalToken: FC<{
  onClose: () => void;
  successToken: (() => void) | null;
  open: boolean;
}> = ({ open, onClose, successToken }) => {
  const [values, setValues] = useState(Array(6).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handlerClose = () => {
    onClose();
  };

  const verifyToken = async () => {
    const code= values.join('');
    if(code.length < 6) {
      toast.error(
        'Debes completar todos los caracteres para verificar el código de autorización.',
        { position: 'top-center' },
      );
      return;
    }
    const verificationResult = await verifyTokenService(code);
    if (successToken && verificationResult) {
      successToken();
      handlerClose();
    } else if (!verificationResult) {
      toast.error(
        'El código de autorización ingresado no es válido. Se ha generado y enviado un nuevo código a su dispositivo móvil.',
        { position: 'top-center' },
      );
      resetForm();
      await sendVerificationToken();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const val = e.target.value;
    if (val.length > 1) return;

    const newValues = [...values];
    newValues[index] = val;
    setValues(newValues);

    if (val && index < values.length - 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  useEffect(() => {
    if (values.every((value) => value !== '')) {
      verifyToken();
    }
  }, [values]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !values[index] && index > 0) {
      const newValues = [...values];
      newValues[index - 1] = '';
      setValues(newValues);
      inputsRef.current[index - 1]?.focus();
    }
  };

  const resetForm = () => {
    setValues(Array(6).fill(''));
    inputsRef.current[0]?.focus();
  }

  useEffect(() => {
    if(open) {
      setTimeout(() => {
        inputsRef.current[0]?.focus();
      },500);
      sendVerificationToken();
    } else {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle textAlign="left">
        Autorización de Seguridad
        <CloseDialogButton onClose={handlerClose} />
      </DialogTitle>
      <DialogContent>
        <Typography>
          Hemos enviado un token de seguridad a su dispositivo móvil. Por favor, ingrese el token recibido en el campo
          a continuación para autorizar las acciones bloqueadas por seguridad.
        </Typography>
        <Grid container spacing={1} justifyContent="center" sx={{ mt: 2 }}>
          {values.map((value, index) => (
            <Grid item key={index}>
              <TextField
                inputRef={(el) => (inputsRef.current[index] = el)}
                value={value}
                onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, index)}
                onKeyDown={(e) => handleKeyDown(e as React.KeyboardEvent<HTMLInputElement>, index)}
                inputProps={{ maxLength: 1, style: { textAlign: 'center', borderRadius: '4px' } }}
                sx={{ width: '40px' }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={handlerClose}>Cancelar</CancelButton>
        <ConfirmButton handleClick={verifyToken}>
          <strong>Verificar</strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
