export enum UserStatusEnum {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING',
  AuthorizationPending = 'AUTHORIZATION_PENDING',
}

export enum UserStatusEnumSpanish {
  ACTIVE = 'Activo',
  DEACTIVATED = 'Deshabilitado',
  PENDING = 'Pendiente',
  AUTHORIZATION_PENDING = 'En revisión',
}
