import { Dialog, DialogTitle, DialogContent, Skeleton, Button, DialogActions, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { FC, useState, useEffect, ChangeEvent } from 'react';
import { CloseDialogButton } from "../../design/Button/CloseDialogButton";
import { ComplaintDTO } from "../../types/complaint.dto";
import { CancelButton } from "../../components/Shared/Buttons/CancelButton";
import { ConfirmButton } from "../../components/Shared/Buttons/ConfirmButton";
import { ComplaintPriorityEnum, ComplaintPrioritySpanishEnum } from "../../enums/complaint.enum";
import { updateComplaintPriority, updateComplaintResolution } from "../../api/complaint/complaint.api";
import { toast } from "react-toastify";
import { Textarea } from "../../design/theme/shared-style";


export const EditResolutionModal: FC<{
    complaintToDetail: ComplaintDTO | null;
    reloadComplaints: () => Promise<void>;
    loadSavedComplaint?: (complaint: ComplaintDTO) => void;
    onClose: () => void;
    open: boolean;
    holdingState?: {
        droppableId: string,
        index: number
    } | null;
}> = ({ open, onClose, complaintToDetail, reloadComplaints, holdingState, loadSavedComplaint }) => {
    const [resolutionBody, setResolutionBody] = useState(complaintToDetail?.bodyResolution || '');
    const saveResolution = async () => {
        if (complaintToDetail) {
            toast.info('Actualizando resolución...', {
                position: toast.POSITION.TOP_CENTER,
            });
            const savedComplaint = await updateComplaintResolution({
                complaintId: complaintToDetail?.id,
                newBodyResolution: resolutionBody,
                holdingState
            });
            if (loadSavedComplaint && savedComplaint) {
                loadSavedComplaint(savedComplaint);
                toast.dismiss();
                toast.success('Resolución actualizada correctamente', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
        reloadComplaints();
        handleClose();
    };
    const handleClose = () => {
        onClose();
        setResolutionBody('');
    };
    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const maxCharactersBody = 240;
        if (resolutionBody.length < maxCharactersBody || event.target.value.length < resolutionBody.length) {
            setResolutionBody(event.target.value);
        }
    }
    useEffect(() => {
        setResolutionBody(complaintToDetail?.bodyResolution || '');
    }, [open]);
    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xs'}>
            <DialogTitle textAlign="left">
                <div>Editar resolución</div>
                <CloseDialogButton onClose={handleClose} />
            </DialogTitle>
            <DialogContent>
                <div style={{ paddingTop: '5px' }}>
                    <FormControl fullWidth>
                        <Textarea minRows="4" placeholder="Escribe aquí tu resolución" value={resolutionBody}
                            onChange={handleTextChange} style={{ maxWidth: '100%', width: '100%', minWidth: '100%', minHeight: '30px' }}></Textarea>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }} style={{
                justifyContent: 'flex-end', paddingTop: '5px',
                paddingLeft: '0', paddingRight: '0'
            }}>
                <div style={{ marginRight: '27px' }}>
                    <CancelButton handleClick={handleClose}>Cancelar</CancelButton>
                    <ConfirmButton handleClick={saveResolution}>
                        <strong>Guardar</strong>
                    </ConfirmButton>
                </div>
            </DialogActions>
        </Dialog>
    );
}