import { createAction } from '@reduxjs/toolkit';

export const setAuthTokenAction = createAction(
  'SET_AUTH_TOKEN',
  (token: string) => {
    return { payload: token };
  },
);

export const setLoggedUserAction = createAction(
  'SET_LOGGED_USER',
  (user: any) => {
    return { payload: user };
  },
);

export const removeAuthTokenAction = createAction('REMOVE_AUTH_TOKEN');
export const removeLoggedUser = createAction('REMOVE_LOGGED_USER');
