import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Draggable } from '@hello-pangea/dnd';
import { firstLetterUppercase, trimStringToMaxLength } from '../../utilities/i18n/helpers';
import { ComplaintDTO } from '../../types/complaint.dto';
import { ComplaintPrioritySpanishEnum } from '../../enums/complaint.enum';
import { StyledTextH2 } from '../../design/theme/shared-style';
import { markComplaintAsReaded } from '../../api/complaint/complaint.api';

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 106px;
  border-radius: 5px;
  max-width: 311px;
  background: white;
  margin-top: 15px;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
`;

const NewComplain = styled.div`
  border-radius: 100%;
  background-color: #bb86fc;
  width: 10px;
  height: 10px;
`;

const PriorityLevel = styled.div`
  height: 100%;
  width: 12px;
  background-color: ${props => {
    switch (props.color) {
      case 'LOW':
        return '#87CEEB';
      case 'MEDIUM':
        return '#FF7F50';
      case 'HIGH':
        return '#DC143C';
      default:
        return '#D3D3D3'
    }
  }};
  border-radius: 5px 0px 0px 5px;
  margin-right: 10px;
`;

const TaskCard = ({ item, index, open }: { item: ComplaintDTO, index: number, open: (item: ComplaintDTO) => void }) => {
  const markAsReaded = async (id: string) => {
    await markComplaintAsReaded(id);
};
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => {
            open(item);
            setTimeout(() => item.readed = true, 800);
            if(!item?.readed) {
              markAsReaded(item.id);
            }
          }}
        >
          <TaskInformation style={{
            height: '50%',
            width: '100%',
            paddingLeft: '0px',
            boxShadow: '3px 3px 8px 1px rgba(0, 0, 0, 0.1)'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row' , height: '100%', width: '100%'}}>
              <PriorityLevel color={item.priority}></PriorityLevel>
              <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', paddingTop: '10px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' , minHeight: '65%'}}>
                  <StyledTextH2 style={{fontSize: '14px', maxWidth: '95%'}}>{firstLetterUppercase(trimStringToMaxLength(item.title))}</StyledTextH2>
                  {
                    !item.readed &&
                    <NewComplain></NewComplain>
                  }
                </div>
                <div className="secondary-details">
                  <p>
                    {item.anonymous ? 'Anónimo' : trimStringToMaxLength(`${item.user?.name} ${item.user?.firstLastname}`, 32)}
                  </p>
                    <div>
                  <p>
                    {item.type?.name}
                  </p>
                  <p>
                    {new Date(item.statusDates[0].date).toLocaleDateString('es-ES', {
                      month: 'long',
                      day: '2-digit',
                    })}
                  </p>
                    </div>
                </div>
              </div>
            </div>
          </TaskInformation>
        </div>
      )}
    </Draggable>
  );
};
export default TaskCard;
