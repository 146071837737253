import styled from "styled-components";
import { Text } from 'design';
import { Box, TextareaAutosize } from "@mui/material";

export const StyledBoxWithPadding = styled(Box)`
    padding-left: 2vw;
    padding-right: 2vw;

    @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const StyledTextH2 = styled(Text.h2)`
    font-weight: 700;
`;

export const Textarea = styled(TextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    margin-top: 25px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 5px;
    // firefox
    &:focus-visible {
        outline: 0;
    }
    `,
);

export const Title = styled(Text.p)(
    ({ theme }) => `
    font-size: 27px;
    `,
);

export const StyledTextInfo = styled(Text.h6)`
font-size: 20px;
line-height: 25px;
margin-bottom: 25px;
`;

export const StyledP = styled.p`
margin-top: 300px;
`;

export const StyledTitleContainer = styled.div`
display: flex;
justify-content: center;
align-content: center;
`;