export enum UserPaymentFrequencyEnum {
  Weekly = 'Semanal',
  Monthly = 'Mensual',
  Biweekly = 'Catorcenal',
  Fortnightly = 'Quincenal',
}
export enum UserSex {
  Masculine = 'Masculino',
  Feminine = 'Femenino',
}

export enum UserRole {
  EMPLOYEE = 'EMPLOYEE',
  COMPANY = 'COMPANY',
  ADMIN = 'ADMIN',
}

export enum LoginType {
  AUTH0 = 'AUTH0',
  EMAIL = 'EMAIL'
}