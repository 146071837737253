/* eslint-disable react/prop-types */
import { Box, ListItemIcon, MenuItem } from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { ComplaintDTO, ReportedUsersDTO } from '../../types/complaint.dto';
import { StyledBoxWithPadding, StyledP, StyledTextH2, StyledTitleContainer } from '../../design/theme/shared-style';
import { loadArchivedComplaints, unarchiveComplaintBackend } from '../../api/complaint/complaint.api';
import { User } from '../../types/user.dto';
import { getNamesAndLastnames } from '../../components/User/Employee/Utils/xlsx.utils';
import {
  ComplaintPriorityEnum,
  ComplaintPrioritySpanishEnum,
  ComplaintStatus,
  ComplaintStatusEnum,
} from '../../enums/complaint.enum';
import { trimStringToMaxLength } from '../../utilities/i18n/helpers';

const ArchiveComplaintMailbox = () => {
  const [tableData, setTableData] = useState<ComplaintDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const getArchivedComplaints = async () => {
    try {
      setLoading(true);
      const archivedComplaints = await loadArchivedComplaints();
      setTableData(archivedComplaints);
      setLoading(false);
    } catch (err: any) {
      toast.dismiss();
      toast.error('Ocurrió un error obteniendo el listado.');
      setLoading(false);
    }
  };

  useEffect(() => {
    getArchivedComplaints();
  }, []);

  const handleUnarchiveButton = async (complaintSelected: ComplaintDTO) => {
    try {
      toast.info('Actualizando denuncia...');
      await unarchiveComplaintBackend(complaintSelected.id);
      toast.success('La denuncia ha sido desarchivada correctamente.');
      getArchivedComplaints();
    } catch (err) {
      toast.dismiss();
      toast.error('Ocurrió un error actualizando la denuncia.');
    }
  };

  const columns = useMemo<MRT_ColumnDef<ComplaintDTO>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Título',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{trimStringToMaxLength(props.cell.getValue<string>())}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'body',
        header: 'Descripción',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{trimStringToMaxLength(props.cell.getValue<string>(), 75)}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'status',
        header: 'Estado',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          const status = props.cell.getValue<string>() as ComplaintStatusEnum;
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null' &&
            status
          ) {
            return <span>{ComplaintStatus[status]}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'priority',
        header: 'Prioridad',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          const priority = props.cell.getValue<string>() as ComplaintPriorityEnum;
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null' &&
            priority
          ) {
            return <span>{ComplaintPrioritySpanishEnum[priority]}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'createdAt',
        header: 'Fecha de creación',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (props.cell.getValue<string>() && props.cell.getValue<string>() !== 'null') {
            const formattedDate = moment.utc(props.cell.getValue<string>()).format('DD/MM/YYYY');
            return <span>{formattedDate}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'statusDates',
        header: 'Fecha de cierre',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            const statusClose = props.cell.getValue<{ date: string, status: string }[]>()
              ?.find(statusDate => statusDate.status === 'CLOSE');
            const actualStatus = props.row.original.status;
            if (statusClose && actualStatus === ComplaintStatusEnum.CLOSE) {
              return (
                <span>
                  {new Date(statusClose.date).toLocaleDateString(
                    'es-419',
                  )}
                </span>
              );
            } else {
              return <span>--</span>;
            }
          }
        },
      },
      {
        accessorKey: 'bodyResolution',
        header: 'Resolución',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (props.cell.getValue<string>()) {
            return <span>{trimStringToMaxLength(props.cell.getValue<string>(), 75)}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'user',
        header: 'Denunciante',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<User>().name} {props.cell.getValue<User>().firstLastname}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'reportedUsersEntry',
        header: 'Nombre denunciado',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'usersReported',
        header: 'Colaboradores denunciados',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            const reportedUserNames = getNamesAndLastnames(props.cell.getValue<ReportedUsersDTO[]>());
            return <span>{trimStringToMaxLength(reportedUserNames)}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'type.name',
        header: 'Categoria',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          if (
            props.cell.getValue<string>() &&
            props.cell.getValue<string>() !== 'null'
          ) {
            return <span>{props.cell.getValue<string>()}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
    ],
    [],
  );

  return (
    <StyledBoxWithPadding>
      <Box mx={0} my={2} p={0}>
        <StyledTextH2>
          Denuncias Archivadas
        </StyledTextH2>
      </Box>
      <div style={{ width: '100%' }}>
        {loading ? <Skeleton
          style={{ width: '100%', height: '100px', marginTop: '50px' }} /> : tableData && tableData.length > 0 && (
          <MaterialReactTable
            initialState={{ density: 'compact' }}
            enableStickyHeader
            enableStickyFooter
            columns={columns}
            defaultColumn={{
              minSize: 20,
              maxSize: 160,
              size: 50,
            }}
            data={tableData}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: '0',
              },
            }}
            enableRowActions={true}
            enableRowSelection={false}
            enableRowOrdering={false}
            enableHiding={false}
            enableFullScreenToggle={false}
            enableMultiRowSelection={false}
            positionActionsColumn="first"
            renderRowActionMenuItems={({ closeMenu, row }) => [
              <MenuItem
                key={0}
                onClick={() => {
                  handleUnarchiveButton(row.original);
                  closeMenu();
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <UnarchiveIcon />
                </ListItemIcon>
                Desarchivar
              </MenuItem>,
            ]}
            onPaginationChange={setPagination}
            muiTablePaginationProps={{
              rowsPerPageOptions: [10, 20, 50],
            }}
            state={{
              isLoading: loading,
              pagination,
              columnVisibility: { id: false },
            }}
          />
        )}
        {(!loading && !tableData) ||
          (!loading && tableData && tableData.length === 0 && (
            <StyledTitleContainer>
              <StyledP>No se encontraron denuncias archivadas.</StyledP>
            </StyledTitleContainer>
          ))}
      </div>
    </StyledBoxWithPadding>
  );
};

export default ArchiveComplaintMailbox;
