import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
} from '@mui/material';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { FC, useState, useEffect } from 'react';
import { DateInput } from '../../../design/input/DateTime/DateInput';
import { UserEndReasonEnum } from '../../../types/end-reason.enum';
import { toast } from 'react-toastify';

export const DeactivateEmployeeModal: FC<{
    onClose: () => void;
    onSubmit: (values: {
        endDate: Date,
        endReason: string
    }) => void;
    open: boolean;
}> = ({ open, onClose, onSubmit }) => {
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [endReason, setReason] = useState<string>(UserEndReasonEnum.OTHER);

    const isValidDate = (dateString: any) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime()) || date.getFullYear() < 1000) {
            return false;
        }
        if (dateString.trim && dateString.trim().length !== 10) {
            return false;
        }
        return true;
    };
    const handleSubmit = () => {
        if (!endDate) {
            toast.error('La fecha de egreso es requerida', {
                position: 'top-center',
            });
            return;
        }

        if (!endReason) {
            toast.error('Una razón es requerida', {
                position: 'top-center',
            });
            return;
        }
        onSubmit({
            endDate,
            endReason
        });
        onClose();
    };

    useEffect(() => {
        setEndDate(new Date());
        setReason(UserEndReasonEnum.OTHER);
    }, [open])

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle textAlign="left">
                Deshabilitar colaborador
                <CloseDialogButton onClose={onClose} />
            </DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        mt={1}
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                            padding: '2',
                        }}
                    >
                        <DateInput
                            key="endDate"
                            label="Fecha de egreso"
                            name="endDate"
                            value={endDate}
                            onChange={(e) => {
                                const dateValue = e.target.value;
                                if (dateValue && isValidDate(dateValue)) {
                                    setEndDate(dateValue);
                                } else {
                                    setEndDate(new Date());
                                }
                            }
                            }
                        />
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="select-reason">Razón</InputLabel>
                                <Select
                                    labelId="select-reason"
                                    id="select-reason"
                                    value={endReason}
                                    label="Razón"
                                    name="reason"
                                    onChange={(e) => {
                                        setReason(e.target.value);
                                    }}
                                >
                                    <MenuItem value={UserEndReasonEnum.VOLUNTARY}>Voluntaria</MenuItem>
                                    <MenuItem value={UserEndReasonEnum.INVOLUNTARY}>Involuntaria</MenuItem>
                                    <MenuItem value={UserEndReasonEnum.OTHER}>Otra</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <CancelButton handleClick={onClose}>Cancelar</CancelButton>
                <ConfirmButton handleClick={handleSubmit} disabled={!endReason || !endDate}>
                    <strong>Deshabilitar</strong>
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    );
};
