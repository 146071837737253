import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Stack,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';

export const EditEmployeePasswordModal: FC<{
    onClose: () => void;
    onSubmit: (newPassword: string) => void;
    open: boolean;
}> = ({ open, onClose, onSubmit }) => {
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = () => {
        if (!password) {
            toast.error('La contraseña es requerida', {
                position: 'top-center',
            });
            return;
        }

        if (!confirmPassword) {
            toast.error('La confirmación de contraseña es requerida', {
                position: 'top-center',
            });
            return;
        }

        if (password !== confirmPassword) {
            toast.error('Las contraseñas no coinciden', {
                position: 'top-center',
            });
            return;
        }

        if (password.length < 6) {
            toast.error('La contraseña debe tener al menos 6 caracteres de longitud', {
                position: 'top-center',
            });
            return;
        }
        onSubmit(password);
        handleOnClose();
    };

    const handleOnClose = () => {
        onClose();
        setPassword('');
        setConfirmPassword('');
        setShowConfirmPassword(false);
        setShowPassword(false);
    }

    const handleToggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle textAlign="left">
                Cambiar contraseña
                <CloseDialogButton onClose={handleOnClose} />
            </DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        mt={1}
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                            padding: '2',
                        }}
                    >
                        <TextField
                            label="Nueva Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleToggleShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Confirmar Nueva Contraseña"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            fullWidth
                            type={showConfirmPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleToggleShowConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <CancelButton handleClick={handleOnClose}>Cancelar</CancelButton>
                <ConfirmButton handleClick={handleSubmit} disabled={!password || !confirmPassword || password !== confirmPassword}>
                    <strong>Guardar</strong>
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    );
};
