import { AppRoute } from 'enums/app-route.enum';

export const navItems = [
  {
    label: 'Dashboard',
    to: AppRoute.Dashboard,
  },
  {
    label: 'Administradores',
    to: AppRoute.Admins,
  },
  {
    label: 'Colaboradores',
    to: AppRoute.Employees,
  },
  {
    label:'Notificaciones',
    to: AppRoute.Notifications,
  },
  {
    label:'Configuración',
    to: AppRoute.Config,
  },
  {
  label:'Buzón de denuncias',
  to: AppRoute.ComplaintMailbox,
  },
  {
    label:'Autorizaciones',
    to: AppRoute.Authorizations,
  },
];
