import { FC, useEffect, useState } from 'react';
import { StyledBoxWithPadding, StyledTextH2 } from '../../design/theme/shared-style';
import { Align } from 'layout';
import { Box, Button } from '@mui/material';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { NewNotificationModal } from './NewNotificationModal';
import { sendPushNotification } from '../../api/firebaseNotification/firebaseNotification.api';
import { getDropdownNotificationFilter, listCompanies } from '../../api/user/user.api';
import { toast } from 'react-toastify';
import NotificationsList from './NotificationsList';
import { EditNotificationModal } from './EditNotificationModal';
import { deleteNotification, editNotifications } from '../../api/notifications/notifications.api';
import CompaniesDropDown from '../User/Employee/CompanyDropdown';
import { UserRoleEnum } from '../../types/user-role.enum';
import { selectUser } from '../../global-state/selectors';
import { useSelector } from 'react-redux';
import {
  CurrentNotificationInfoDTO,
  EditedNotificationInfoDTO,
  NewNotificationDTO,
} from '../../types/notifications.dto';
import { StorageItem } from '../../enums/storage-item.enum';
import { storage } from '../../helpers/storage.helpers';
import { Profile } from '../../types/user.dto';

export const NotificationsPush: FC = () => {
  const [createNotificationOpen, setCreateNotificationOpen] = useState(false);
  const [editNotificationOpen, setEditNotificationOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const loggedUser = useSelector(selectUser);
  const [currentNotificationEdit, setCurrentNotificationEdit] = useState({
    currentBody: '',
    currentTitle: '',
    currentArea: '',
    currentLocation: '',
    notificationId: '',
    notificationCompanyName: '',
  });
  const [areaData, setAreaData] = useState([] as string[]);
  const [locationData, setLocationData] = useState([] as string[]);
  const [profilesData, setProfilesData] = useState([] as Profile[]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const selectedCompany: {
    entityName: string;
    complaintsEnable: boolean;
    notificationsEnable: boolean;
    id: string;
  } | null = storage.session.get(StorageItem.COMPANY_SELECTED);

  useEffect(() => {
    loadDropdownOptions();
    loadCompanies();
  }, []);

  const loadCompanies = async () => {
    if (loggedUser?.role === UserRoleEnum.ADMIN) {
      const companiesList = await listCompanies();
      if (companiesList && companiesList.length > 0) {
        setCompanies(companiesList);
      }
    }
  };
  const setCompanyFromDropDown = async (companyId: string) => {
    setSelectedCompanyId(companyId);
    handleReloadList();
  };
  const handleReloadList = () => {
    setReloadList(!reloadList);
  };

  const loadDropdownOptions = async (companySelected?: string) => {
    const dropdownOptions = await getDropdownNotificationFilter(companySelected);
    setLocationData(dropdownOptions.locationsToSend);
    setAreaData(dropdownOptions.areasToSend);
    setProfilesData(dropdownOptions.profiles);
  };

  const handleNewNotification = async (newNotificationData: NewNotificationDTO) => {
    toast.info('Cargando información.');
    const successCounter = await sendPushNotification(newNotificationData);
    toast.dismiss();
    if (successCounter) {
      if (loggedUser?.role === UserRoleEnum.ADMIN) {
        toast.success('La notificación se ha enviado correctamente.');
      } else {
        toast.success('La solicitud de notificación se ha creado correctamente. Ahora debe ser aprobada.');
      }
    } else {
      toast.error('Ocurrió un problema generando la solicitud de notificación.');
    }
    handleReloadList();
    setCreateNotificationOpen(false);
  };

  const handleEditNotification = async (editNotificationData: EditedNotificationInfoDTO) => {
    try {
      await editNotifications(editNotificationData);
      setEditNotificationOpen(false);
      handleReloadList();
      toast.success('Se modificó la notificación correctamente.');
    } catch (error) {
      console.log('error handleEditNotification', error);
      toast.error('Ocurrió un error modificando la notificación.');
    }
  };
  const handleDeleteNotification = async (notificationId: string) => {
    try {
      await deleteNotification(notificationId);
      handleReloadList();
      toast.success('Se eliminó la notificación correctamente.');
    } catch (error) {
      console.log('error handleDeleteNotification', error);
      toast.error('Ocurrió un error eliminando la notificación.');
    }
  };

  const updateSelectOptions = (companySelected: string) => {
    loadDropdownOptions(companySelected);
  };
  const handleNewNotificationOpen = () => {
    loadDropdownOptions();
    setCreateNotificationOpen(true);
  };
  const newNotificationOnClose = () => {
    if (loggedUser?.role === UserRoleEnum.ADMIN) {
      setLocationData([]);
      setAreaData([]);
      setProfilesData([]);
    }
    setCreateNotificationOpen(false);
  };

  return (
    <StyledBoxWithPadding>
      {selectedCompany && selectedCompany.notificationsEnable && (
        <>
          <Box mx={0} my={2} p={0}>
            <StyledTextH2>
              Notificaciones
            </StyledTextH2>
          </Box>
          <Align v-center>
            <Box mb={1} mr={2}
                 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Button
                startIcon={<NotificationAddIcon />}
                onClick={() => {
                  handleNewNotificationOpen();
                }}
                variant="outlined"
                style={{ height: '36px' }}
              >
                Enviar notificación
              </Button>
              <Box style={{ flexGrow: 1 }} />
                {loggedUser?.role === UserRoleEnum.ADMIN && companies && companies.length > 0 && (
                  <CompaniesDropDown
                    companies={companies}
                    setCompanyFromDropDown={setCompanyFromDropDown}
                    disableMargin={true}
                  />
                )}
            </Box>
          </Align>
          <NotificationsList openEdit={(currentNotificationInfo: CurrentNotificationInfoDTO) => {
            setCurrentNotificationEdit(currentNotificationInfo);
            setEditNotificationOpen(true);
          }
          }
                             reloadList={reloadList}
                             filterByCompany={selectedCompanyId}
                             deleteNotification={handleDeleteNotification} />
          <NewNotificationModal
            open={createNotificationOpen}
            onClose={newNotificationOnClose}
            onSubmit={handleNewNotification}
            areaToSelect={areaData}
            locationToSelect={locationData}
            profileToSelect={profilesData}
            companies={companies}
            updateSelectorHanlder={updateSelectOptions}
          />
          <EditNotificationModal
            open={editNotificationOpen}
            onClose={() => {
              setLocationData([]);
              setAreaData([]);
              setProfilesData([]);
              setEditNotificationOpen(false);
            }}
            onSubmit={handleEditNotification}
            currentNotificationInfo={currentNotificationEdit}
          />
        </>)
      }
    </StyledBoxWithPadding>
  );
};