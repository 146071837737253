import { Box, styled } from '@mui/material';
import ListAdminTable from 'components/User/Admin/ListAdminTable';
import { Text } from 'design';
import { Align } from 'layout';
import { FC } from 'react';
import { intl } from 'utilities/i18n/intl.utility';

const StyledTextH2 = styled(Text.h2)`
  font-weight: 700;
`;

const StyledBoxWithPadding = styled(Box)`
  padding-left: 2vw;
  padding-right: 2vw;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const UserManagement: FC = () => {
  return (
    <StyledBoxWithPadding>
      <Box mx={0} my={2} p={0}>
        <StyledTextH2>
          Listado De Administradores
        </StyledTextH2>
      </Box>

      <Align v-center>
        <ListAdminTable />
      </Align>
    </StyledBoxWithPadding>
  );
};
