import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { selectUser } from 'global-state/selectors';
import { validateEmail } from 'helpers/validation.helpers';
import { MRT_ColumnDef } from 'material-react-table';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { User } from 'types/user.dto';
import { intl } from 'utilities/i18n/intl.utility';

export const CreateAdminModal: FC<{
  columns: MRT_ColumnDef<User>[];
  onClose: () => void;
  onSubmit: (values: User) => void;
  open: boolean;
}> = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const loggedUser = useSelector(selectUser);

  const handleSubmit = () => {
    if (!values.name) {
      toast.error(
        'El nombre es requerido',
        {
          position: 'top-center',
        },
      );
      return;
    }

    if (!values.email) {
      toast.error(
        'El email es requerido',
        {
          position: 'top-center',
        },
      );
      return;
    }

    if (!values.password) {
      toast.error(
        'La contraseña es requeria',
        {
          position: 'top-center',
        },
      );
      return;
    }

    if (!validateEmail(values.email)) {
      toast.error(
        'Ingresa un email correcto',
        {
          position: 'top-center',
        },
      );
      return;
    }

    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle textAlign="left">
        Crear Nuevo Administrador
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            mt={1}
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              padding: '2',
            }}
          >
            {columns.map((column) => {
              if (
                column.accessorKey === 'id' ||
                column.accessorKey === 'lastActive' ||
                column.accessorKey === 'role' ||
                column.accessorKey === 'updatedAt' ||
                column.accessorKey === 'lastLoginDate' ||
                column.accessorKey === 'status'
              ) {
                return;
              }

              return (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                />
              );
            })}

            {
              <TextField
                key="password"
                label="Contraseña"
                name="password"
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            }
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          Cancelar
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            Guardar
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
