import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC } from 'react';
import { CloseDialogButton } from '../../../design/Button/CloseDialogButton';
import { Title } from '../../../design/theme/shared-style';
import { ConfirmButton } from '../../../components/Shared/Buttons/ConfirmButton';
import { Notifications } from '../../../types/notifications.dto';
import {
  BodyContainer,
  BodyText,
  LabelInformation,
  StandarInformation,
} from '../../ComplaintMailbox/complaintMailboxStyle';


export const NewNotificationDetailsModal: FC<{
  onClose: () => void,
  open: boolean,
  notificationSelected: Notifications | null,
  submit: () => void,
  reject: () => void,
}> = ({ onClose, open, notificationSelected, submit, reject}) => {

  const handlerClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    submit();
    onClose();
  };

  const handleReject = () => {
    reject();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle textAlign="left" mb={1} style={{ marginBottom: '0px' }}>
        <Title>Autorización de Mensaje Corporativo</Title>
        <CloseDialogButton onClose={handlerClose} />
      </DialogTitle>
      <DialogContent>
        <LabelInformation style={{ color: '#7d7d7d', marginTop: '10px' }}>Audiencia:</LabelInformation>
        <div style={{display: 'flex'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <LabelInformation style={{ color: '#7d7d7d' }}>Área:</LabelInformation>
            <StandarInformation>
              {notificationSelected?.filters?.area
                ? notificationSelected.filters.area === 'TODAS'
                  ? 'Todas las áreas'
                  : notificationSelected.filters.area
                : ''}
            </StandarInformation>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <LabelInformation style={{ color: '#7d7d7d', marginLeft: '1rem' }}>Ubicación:</LabelInformation>
            <StandarInformation>
              {notificationSelected?.filters.location
                ? notificationSelected?.filters.location === 'TODAS'
                  ? 'Todas las ubicaciones'
                  : notificationSelected?.filters.location
                : ''}
            </StandarInformation>
          </div>
        </div>
        <LabelInformation style={{ color: '#7d7d7d', marginTop: '10px' }}>Título:</LabelInformation>
        <BodyContainer style={{ marginTop: '0px' }}>
          <BodyText>{notificationSelected?.title || ''}</BodyText>
        </BodyContainer>
        <LabelInformation style={{ color: '#7d7d7d', marginTop: '10px'  }}>Cuerpo:</LabelInformation>
        <BodyContainer style={{ marginTop: '0px' }}>
          <BodyText>{notificationSelected?.body || ''}</BodyText>
        </BodyContainer>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>Autorizar</strong>
        </ConfirmButton>
        <ConfirmButton handleClick={handleReject}>
          <strong>Rechazar</strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  )
};