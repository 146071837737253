import { Add, AdminPanelSettings, PersonOff } from '@mui/icons-material';
import { Box, Button, ListItemIcon, MenuItem } from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import {
  activateUser,
  createUser,
  deactivateUser,
  editUser,
  listUser,
} from 'api/user/user.api';
import { selectUser } from 'global-state/selectors';
import { validateEmail, validateRequired } from 'helpers/validation.helpers';
import MaterialReactTable, {
  MRT_Cell,
  MRT_ColumnDef,
} from 'material-react-table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Edit } from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UserEditApi } from 'types/user-edit-api.dto';
import { UserRequestApi } from 'types/user-request-api.dto';
import { UserRoleEnum } from 'types/user-role.enum';
import { UserStatusEnum } from 'types/user-status.enum';
import { User } from 'types/user.dto';
import { intl } from 'utilities/i18n/intl.utility';
import { CreateAdminModal } from './CreateAdminModal';
import { EditAdminModal } from './EditAdminModal';

const ListAdminTable: FC = () => {
  const [tableData, setTableData] = useState<User[]>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [CreateAdminModalOpen, setCreateAdminModalOpen] = useState(false);
  const [EditAdminModalOpen, setEditAdminModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User>();
  const loggedUser = useSelector(selectUser);

  const getUsers = async () => {
    setLoading(true);
    const users = await listUser();
    setTableData(users);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleInviteUser = async (userTableData: User) => {
    const user: UserRequestApi = {
      name: userTableData.name,
      email: userTableData.email,
      password: userTableData.password,
    };

    try {
      await createUser(user);
      toast.info(
        'El registro ha sido creado',
        {
          position: 'top-center',
        },
      );
      await getUsers();
    } catch (error) {
      toast.error(
        'Ha ocurrido un error, intenta nuevamente',
        { position: 'top-center' },
      );
    }
  };

  const handleEditButton = (userSelectedRow: User) => {
    setUserToEdit(userSelectedRow);
    setEditAdminModalOpen(true);
  };

  const handleEditUser = async (userData: UserEditApi) => {
    try {
      if (userToEdit) {
        await editUser(userToEdit.id, userData);
        toast.success('El registro ha sido actualizado', {
          position: 'top-center',
        });
        await getUsers();
      }
    } catch (error) {
      toast.error(
        'Ha ocurrido un error, intenta nuevamente',
        { position: 'top-center' },
      );
    }
  };

  const handleDeactivate = async (userSelectedRow: User) => {
    try {
      const result = confirm(
        `¿Quieres desactivar al usuario ${userSelectedRow.email}?`,
      );

      if (result) {
        await deactivateUser(userSelectedRow.id);
        toast.info(
          'El usuario ha sido desactivado',
          {
            position: 'top-center',
          },
        );

        await getUsers();
      }
    } catch (error) {
      toast.error(
        'Ha ocurrido un error, intenta nuevamente',
        { position: 'top-center' },
      );
    }
  };

  const handleActivate = async (userSelectedRow: User) => {
    try {
      const result = confirm(
        `¿Quieres activar a ${userSelectedRow.email}?`,
      );

      if (result) {
        await activateUser(userSelectedRow.id);
        toast.info(
          'El usuario ha sido activado',
          {
            position: 'top-center',
          },
        );

        await getUsers();
      }
    } catch (error) {
      toast.error(
        'Ha ocurrido un error, intenta nuevamente',
        { position: 'top-center' },
      );
    }
  };

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<User>,
    ): MRT_ColumnDef<User>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'status',
        header: 'Habilitado',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          if (props.cell.getValue<string>() === 'ACTIVE') {
            return <span>✅ Activo</span>;
          } else {
            return <span>❌ Deshabilitado</span>;
          }
        },
      },
      {
        accessorKey: 'name',
        header: 'Nombre',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email',
        }),
      },
      {
        accessorKey: 'role',
        header: 'Rol',
        size: 140,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: Object.values(UserRoleEnum).map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: 'lastLoginDate',
        header: 'Última vez activo',
        size: 80,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          const opciones: any = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          };
          // eslint-disable-next-line react/prop-types
          if (props.cell.getValue<any>()) {
            const dateString = new Date(
              // eslint-disable-next-line react/prop-types
              props.cell.getValue<any>(),
            ).toLocaleString(undefined, opciones);
            return <span>{dateString}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'updatedAt',
        header: 'Última modificación',
        size: 80,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          const opciones: any = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          };
          // eslint-disable-next-line react/prop-types
          if (props.cell.getValue<string>()) {
            const dateString = new Date(
              // eslint-disable-next-line react/prop-types
              props.cell.getValue<string>(),
            ).toLocaleString(undefined, opciones);
            return <span>{dateString}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
    ],
    [getCommonEditTextFieldProps],
  );

  return (
    <div style={{ width: '100%' }}>
      <Box mb={2}>
        <Button
          startIcon={<Add />}
          onClick={() => setCreateAdminModalOpen(true)}
          variant="outlined"
        >
          Crear Administrador
        </Button>
      </Box>

      <MaterialReactTable
        enableStickyHeader
        enableStickyFooter
        columns={columns}
        defaultColumn={{
          minSize: 20,
          maxSize: 160,
          size: 50,
        }}
        data={tableData}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '0',
          },
        }}
        enableRowActions={loggedUser?.role === UserRoleEnum.ADMIN}
        enableRowSelection={false}
        enableRowOrdering={false}
        enableHiding={false}
        enableFullScreenToggle={false}
        enableMultiRowSelection={false}
        positionActionsColumn="first"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              handleEditButton(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Modificar
          </MenuItem>,

          <MenuItem
            key={1}
            onClick={() => {
              handleActivate(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
            disabled={row.original.status !== UserStatusEnum.Deactivated}
          >
            <ListItemIcon>
              <AdminPanelSettings />
            </ListItemIcon>
            Habilitar
          </MenuItem>,

          <MenuItem
            key={2}
            onClick={() => {
              handleDeactivate(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
            disabled={row.original.status === UserStatusEnum.Deactivated}
          >
            <ListItemIcon>
              <PersonOff />
            </ListItemIcon>
            Deshabilitar
          </MenuItem>,
        ]}
        onPaginationChange={setPagination}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        state={{
          isLoading: loading,
          pagination,
          columnVisibility: { id: false },
        }}
      />

      <CreateAdminModal
        columns={columns}
        open={CreateAdminModalOpen}
        onClose={() => setCreateAdminModalOpen(false)}
        onSubmit={handleInviteUser}
      />

      {userToEdit && (
        <EditAdminModal
          open={EditAdminModalOpen}
          userToEdit={userToEdit}
          onClose={() => setEditAdminModalOpen(false)}
          onSubmit={handleEditUser}
        />
      )}
    </div>
  );
};

export default ListAdminTable;
