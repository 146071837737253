export enum AppRoute {
  LogIn = '/',
  Dashboard = '/dashboard',
  Notifications = '/notifications',
  Admins = '/admins',
  Companies = '/users/companies',
  Employees = '/employees',
  Transactions = '/transactions',
  TermsAndConditions = '/terms-and-conditions',
  Config = '/config',
  SetPassword = '/set-password/:token',
  Auth0Callback = '/auth0-callback',
  ComplaintMailbox = '/complaint-mailbox',
  Authorizations = '/authorizations',
  ArchiveComplaintMailBox = '/complaint-mailbox/archived'
}
