import { http } from 'utilities/http/http';
import { NewNotificationDTO } from '../../types/notifications.dto';

export async function sendPushNotification(newNotificationData: NewNotificationDTO): Promise<boolean> {
    try {
        const response = await http.post<{
            success: boolean;
            data: boolean;
        }>('/setTokenFirebase/send-notification', newNotificationData);

        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error sending notification');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}
