import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import * as Yup from 'yup';
import { Button, Card, NumberInput, TextInput } from 'design';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ThemeProvider,
} from '@mui/material';
import { violetTheme } from '../../design/theme/palette';
import { genericErrorHandler } from '../../helpers/error.helpers';
import { toast } from 'react-toastify';
import { getCurrentSettings, saveConfig } from '../../api/config/config.api';
import { Settings } from '../../../backend/src/entities/settings.entity';
import { useFormik } from 'formik';
import { Spinner } from '../Spinner/Spinner';
import { InputChangeEvent } from '../../design/types/input-change-event';

const StyledInputsContainer = styled.div`
  margin: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const StyledInput = styled.div`
  width: 60%;
  padding: 10px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const StyledButton = styled.div`
  margin-top: 50px;
  margin-left: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const initialValues = {
  payerAccount: ' ',
  transactionPayerName: ' ',
  transactionDescription: ' ',
  transactionBenficiaryAccountType: ' ',
  transactionPayerAccountType: ' ',
  processorApiAddress: ' ',
  processorApiPrivatekey: ' ',
  processorApiXCustomAuth: ' ',
  processorApiDefaultTimezoneSet: ' ',
  price: 1,
  minimum: 1,
  maximum: 2,
  waitTime: 1,
  maxTransactionsPerDay: 1,
  maxTransactionsPerPeriod: 1,
  maxWithdrawalPerWeek: 1,
  depositAlertEmail: 'example@domain.com',
  maxTransactionAlertEmail: 'example@domain.com',
  minPrice: 19,
  maxPrice: 39,
  vatPrice: 16,
  transactionPaymentType: 1,
  maxWithdrawalPerDay: 5000,
  maxTransactionsPerWeek: 5000,
  maxAvailableTwispi: 80,
};

const validationSchema = Yup.object({
  payerAccount: Yup.string().required('El campo es requerido'),
  transactionPayerName: Yup.string().required('El campo es requerido'),
  transactionDescription: Yup.string().required('El campo es requerido'),
  transactionBenficiaryAccountType: Yup.string().required(
    'El campo es requerido',
  ),
  transactionPayerAccountType: Yup.string().required('El campo es requerido'),
  processorApiAddress: Yup.string().required('El campo es requerido'),
  processorApiPrivatekey: Yup.string().required('El campo es requerido'),
  processorApiXCustomAuth: Yup.string().required('El campo es requerido'),
  processorApiDefaultTimezoneSet: Yup.string().required(
    'El campo es requerido',
  ),
  price: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .required('El campo es requerido'),
  minimum: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .integer('El número debe ser un entero')
    .required('El campo es requerido'),
  maximum: Yup.number()
    .moreThan(Yup.ref('minimum'), 'Este campo debe ser mayor al mínimo')
    .required('El campo es requerido'),
  waitTime: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .integer('El número debe ser un entero')
    .required('El campo es requerido'),
  maxTransactionsPerDay: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .integer('El número debe ser un entero')
    .required('El campo es requerido'),
  maxTransactionsPerPeriod: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .integer('El número debe ser un entero')
    .required('El campo es requerido'),
  maxWithdrawalPerWeek: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .required('El campo es requerido'),
  depositAlertEmail: Yup.string()
    .email('Dirección de email inválida')
    .required('El campo es requerido'),
  maxTransactionAlertEmail: Yup.string()
    .email('Dirección de email inválida')
    .required('El campo es requerido'),
  minPrice: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .required('El campo es requerido'),
  maxPrice: Yup.number()
    .moreThan(Yup.ref('minPrice'), 'Este campo debe ser mayor al precio mínimo')
    .required('El campo es requerido'),
  vatPrice: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .required('El campo es requerido'),
  maxWithdrawalPerDay: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .required('El campo es requerido'),
  maxTransactionsPerWeek: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .required('El campo es requerido'),
  maxAvailableTwispi: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .required('El campo es requerido'),
  transactionPaymentType: Yup.number()
    .min(0)
    .positive('El número debe ser positivo')
    .required('El campo es requerido'),
});

type FormValues = {
  payerAccount: string;
  transactionPayerName: string;
  transactionDescription: string;
  transactionBenficiaryAccountType: string;
  transactionPayerAccountType: string;
  processorApiAddress: string;
  processorApiPrivatekey: string;
  processorApiXCustomAuth: string;
  processorApiDefaultTimezoneSet: string;
  price: number;
  minimum: number;
  maximum: number;
  waitTime: number;
  maxTransactionsPerDay: number;
  maxTransactionsPerPeriod: number;
  maxWithdrawalPerWeek: number;
  depositAlertEmail: string;
  maxTransactionAlertEmail: string;
  minPrice: number;
  maxPrice: number;
  vatPrice: number;
  transactionPaymentType: number;
  maxWithdrawalPerDay: number;
  maxTransactionsPerWeek: number;
  maxAvailableTwispi: number;
};

export const ConfigForm: FC<FcDefaultProps> = () => {
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState('#BF8BFF');

  useEffect(() => {
    getCurrentSettingsAndSetValues();
  }, []);

  const maxWithdrawalPerDayHandler = (event: InputChangeEvent<number | "">) => {
    const inputValue = typeof event.target.value === 'string' ? parseInt(event.target.value) : event.target.value;
    if (inputValue <= formikProps.values.maxTransactionsPerWeek) {
      formikProps.setFieldValue('maxWithdrawalPerDay', inputValue.toString());
    } else {
      formikProps.setFieldError('maxWithdrawalPerDay', 'El valor máximo de retiro por día no puede ser menor o igual al máximo de transacciones por semana.');
    }
  }

  const onSubmitedForm = async (values: FormValues) => {
    try {
      if (formikProps.values.maxWithdrawalPerDay > formikProps.values.maxTransactionsPerWeek) {
        toast.error('El límite máximo permitido de retiros diarios no debe sobrepasar el volumen máximo de transacciones establecido para la semana.', {
          position: 'top-center',
        });
        return;
      }
      setLoading(true);
      await saveConfig(values);
      setLoading(false);
      toast.success('Información actualizada');
    } catch (error) {
      genericErrorHandler(error);
      toast.error('Error al guardar información');
    }
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitedForm,
  });

  const getCurrentSettingsAndSetValues = async () => {
    try {
      const currentSettings = await getCurrentSettings();
      if (currentSettings.success) {
        setInitialValues(currentSettings.data);
        setLoading(false);
      }
    } catch (error) {
      genericErrorHandler(error);
      toast.error('Error al setear valores iniciales');
    }
  };

  const setInitialValues = (currentSettings: Settings) => {
    formikProps.setFieldValue('payerAccount', currentSettings.payerAccount);
    formikProps.setFieldValue(
      'transactionPayerName',
      currentSettings.transactionPayerName,
    );
    formikProps.setFieldValue(
      'transactionDescription',
      currentSettings.transactionDescription,
    );
    formikProps.setFieldValue(
      'transactionBenficiaryAccountType',
      currentSettings.transactionBenficiaryAccountType,
    );
    formikProps.setFieldValue(
      'transactionPayerAccountType',
      currentSettings.transactionPayerAccountType,
    );
    formikProps.setFieldValue(
      'processorApiAddress',
      currentSettings.processorApiAddress,
    );
    formikProps.setFieldValue(
      'processorApiPrivatekey',
      currentSettings.processorApiPrivatekey,
    );
    formikProps.setFieldValue(
      'processorApiXCustomAuth',
      currentSettings.processorApiXCustomAuth,
    );
    formikProps.setFieldValue(
      'processorApiDefaultTimezoneSet',
      currentSettings.processorApiDefaultTimezoneSet,
    );
    formikProps.setFieldValue(
      'price',
      currentSettings.price ? currentSettings.price.toString() : '0',
    );
    formikProps.setFieldValue(
      'minimum',
      currentSettings.minimum ? currentSettings.minimum.toString() : '0',
    );
    formikProps.setFieldValue(
      'maximum',
      currentSettings.maximum ? currentSettings.maximum.toString() : '0',
    );
    formikProps.setFieldValue(
      'waitTime',
      currentSettings.waitTime ? currentSettings.waitTime.toString() : '0',
    );
    formikProps.setFieldValue(
      'maxTransactionsPerDay',
      currentSettings.maxTransactionsPerDay
        ? currentSettings.maxTransactionsPerDay.toString()
        : '0',
    );
    formikProps.setFieldValue(
      'maxTransactionsPerPeriod',
      currentSettings.maxTransactionsPerPeriod
        ? currentSettings.maxTransactionsPerPeriod.toString()
        : '0',
    );
    formikProps.setFieldValue(
      'maxWithdrawalPerWeek',
      currentSettings.maxWithdrawalPerWeek
        ? currentSettings.maxWithdrawalPerWeek.toString()
        : '0',
    );
    formikProps.setFieldValue(
      'depositAlertEmail',
      currentSettings.depositAlertEmail,
    );
    formikProps.setFieldValue(
      'maxTransactionAlertEmail',
      currentSettings.maxTransactionAlertEmail,
    );
    formikProps.setFieldValue(
      'minPrice',
      currentSettings.minPrice ? currentSettings.minPrice.toString() : '0',
    );
    formikProps.setFieldValue(
      'maxPrice',
      currentSettings.maxPrice ? currentSettings.maxPrice.toString() : '0',
    );
    formikProps.setFieldValue(
      'vatPrice',
      currentSettings.vatPrice ? currentSettings.vatPrice.toString() : '0',
    );
    formikProps.setFieldValue(
      'transactionPaymentType',
      currentSettings.transactionPaymentType
        ? currentSettings.transactionPaymentType.toString()
        : '0',
    );
    formikProps.setFieldValue(
      'maxWithdrawalPerDay',
      currentSettings.maxWithdrawalPerDay
        ? currentSettings.maxWithdrawalPerDay.toString()
        : '0',
    );
    formikProps.setFieldValue(
      'maxTransactionsPerWeek',
      currentSettings.maxTransactionsPerWeek
        ? currentSettings.maxTransactionsPerWeek.toString()
        : '0',
    );
    formikProps.setFieldValue(
      'maxAvailableTwispi',
      currentSettings.maxAvailableTwispi
        ? currentSettings.maxAvailableTwispi.toString()
        : '0',
    );
  };

  return (
    <>
      {loading ? (
        <Spinner color={color} loading={loading} />
      ) : (
        <Card.Base>
          <form onSubmit={formikProps.handleSubmit}>
            <StyledInputsContainer>
              <StyledInput>
                <TextInput
                  label="Cuenta bancaria"
                  id="payerAccount"
                  name="payerAccount"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.payerAccount}
                  error={formikProps.errors.payerAccount}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Nombre de la empresa"
                  id="transactionPayerName"
                  name="transactionPayerName"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.transactionPayerName}
                  error={formikProps.errors.transactionPayerName}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Descripción de la transacción"
                  id="transactionDescription"
                  name="transactionDescription"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.transactionDescription}
                  error={formikProps.errors.transactionDescription}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Tipo de cuenta del empleado"
                  id="transactionBenficiaryAccountType"
                  name="transactionBenficiaryAccountType"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.transactionBenficiaryAccountType}
                  error={formikProps.errors.transactionBenficiaryAccountType}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Tipo de cuenta de la empresa"
                  id="transactionPayerAccountType"
                  name="transactionPayerAccountType"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.transactionPayerAccountType}
                  error={formikProps.errors.transactionPayerAccountType}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="IP de la API de OPM"
                  id="processorApiAddress"
                  name="processorApiAddress"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.processorApiAddress}
                  error={formikProps.errors.processorApiAddress}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Private Key"
                  id="processorApiPrivatekey"
                  name="processorApiPrivatekey"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.processorApiPrivatekey}
                  error={formikProps.errors.processorApiPrivatekey}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Token de OPM"
                  id="processorApiXCustomAuth"
                  name="processorApiXCustomAuth"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.processorApiXCustomAuth}
                  error={formikProps.errors.processorApiXCustomAuth}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Zona Horaria del server OPM México"
                  id="processorApiDefaultTimezoneSet"
                  name="processorApiDefaultTimezoneSet"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.processorApiDefaultTimezoneSet}
                  error={formikProps.errors.processorApiDefaultTimezoneSet}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Precio"
                  id="price"
                  name="price"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.price}
                  error={formikProps.errors.price}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Mínimo a retirar"
                  id="minimum"
                  name="minimum"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.minimum}
                  error={formikProps.errors.minimum}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Máximo a retirar"
                  id="maximum"
                  name="maximum"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.maximum}
                  error={formikProps.errors.maximum}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Tiempo de espera entre transacciones en minutos"
                  id="waitTime"
                  name="waitTime"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.waitTime}
                  error={formikProps.errors.waitTime}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Máximo de transacciones por día"
                  id="maxTransactionsPerDay"
                  name="maxTransactionsPerDay"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.maxTransactionsPerDay}
                  error={formikProps.errors.maxTransactionsPerDay}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Máximo de transacciones por período"
                  id="maxTransactionsPerPeriod"
                  name="maxTransactionsPerPeriod"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.maxTransactionsPerPeriod}
                  error={formikProps.errors.maxTransactionsPerPeriod}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Máximo de retiros por semana"
                  id="maxWithdrawalPerWeek"
                  name="maxWithdrawalPerWeek"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.maxWithdrawalPerWeek}
                  error={formikProps.errors.maxWithdrawalPerWeek}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Email de alerta de depósito"
                  id="depositAlertEmail"
                  name="depositAlertEmail"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.depositAlertEmail}
                  error={formikProps.errors.depositAlertEmail}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <TextInput
                  label="Email de alerta de máximo de transacciones"
                  id="maxTransactionAlertEmail"
                  name="maxTransactionAlertEmail"
                  type="text"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.maxTransactionAlertEmail}
                  error={formikProps.errors.maxTransactionAlertEmail}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Precio mínimo"
                  id="minPrice"
                  name="minPrice"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.minPrice}
                  error={formikProps.errors.minPrice}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Precio máximo"
                  id="maxPrice"
                  name="maxPrice"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.maxPrice}
                  error={formikProps.errors.maxPrice}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Precio del VAT"
                  id="vatPrice"
                  name="vatPrice"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.vatPrice}
                  error={formikProps.errors.vatPrice}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Tipo de Pago de la Transacción"
                  id="transactionPaymentType"
                  name="transactionPaymentType"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.transactionPaymentType}
                  error={formikProps.errors.transactionPaymentType}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Máximo de retiros por día"
                  id="maxWithdrawalPerDay"
                  name="maxWithdrawalPerDay"
                  onChange={maxWithdrawalPerDayHandler}
                  value={formikProps.values.maxWithdrawalPerDay}
                  error={formikProps.errors.maxWithdrawalPerDay}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Máximo de transacciones por semana"
                  id="maxTransactionsPerWeek"
                  name="maxTransactionsPerWeek"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.maxTransactionsPerWeek}
                  error={formikProps.errors.maxTransactionsPerWeek}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
              <StyledInput>
                <NumberInput
                  label="Máximo disponible Twispi"
                  id="maxAvailableTwispi"
                  name="maxAvailableTwispi"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.maxAvailableTwispi}
                  error={formikProps.errors.maxAvailableTwispi}
                  variant="outlined"
                  fullWidth
                />
              </StyledInput>
            </StyledInputsContainer>
            <StyledButton>
              <ThemeProvider theme={violetTheme}>
                <Button type="submit" color="primary" variant="filled">
                  Guardar
                </Button>
              </ThemeProvider>
            </StyledButton>
          </form>
        </Card.Base>
      )}
    </>
  );
};
